import { handleResponse, handleError, authHeader } from '../../utils/';
import APIHelper from "../../utils/ApiHelper";
import APIURLConstants from "../../utils/APIURLConstants";

export const countryService = 
{ 
    getAllCountries
};

function getAllCountries() {
    return APIHelper.get(APIURLConstants.LIST_COUNTRY, authHeader())
        .then(handleResponse)
        .then(data => {return data; })
        .catch(handleError);
}