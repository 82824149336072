import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import ReportLayout from 'layout/MainLayout/ReportLayout';
import ReportContainer from 'views/pages/report/report-container';
import LoadReportChart from 'views/pages/report/loadReportChart';
// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const MaintenanceComingSoon1 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon1')));
const MaintenanceComingSoon2 = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon2')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));
const Report = Loadable(lazy(() => import('views/pages/report/index')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <ReportLayout />,
    children: [
        {
            path: '/pages/error',
            element: <MaintenanceError />
        },
        {
            path: '/pages/coming-soon1',
            element: <MaintenanceComingSoon1 />
        },
        {
            path: '/pages/coming-soon2',
            element: <MaintenanceComingSoon2 />
        },
        {
            path: '/pages/under-construction',
            element: <MaintenanceUnderConstruction />
        },
        {
            path: '/pages/report',
            element: <Report />
        }, {
            path: '/report/report-container',
            element: <ReportContainer />
        },
        , {
            path: '/report/load-dashboard',
            element: <LoadReportChart />
        }
    ]
};

export default AuthenticationRoutes;
