const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer',
    //WEBAPI_URL: 'https://localhost:7127/v1/api/',
    WEBAPI_URL:'https://reportscapeapi.azurewebsites.net/v1/api/',
};

export default LAYOUT_CONST;
