import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';

// // third-party
import * as yup from 'yup';
import { useFormik } from 'formik';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CreateCampaignReport from './CreateCampaignReport';
import CampaignItemPage from './CampaignItemPage';
import { reportService, campaignService } from 'services';
import { openSnackbar } from 'store/slices/snackbar';
import AlertDialogSlide from 'ui-component/UIDialog/AlertDialogSlide';

// yup validation-schema
const validationSchema = yup.object({
    campaignName: yup.string().min(5, 'Campaign Name should be of minimum 5 characters').required('Campaign Name is Required'),
    clientId: yup.string().required('Client Name is Required')
});

const CreateCampaign = (props) => {
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [campaignReports, setCampaignReports] = useState([]);
    const [addItemClicked, setAddItemClicked] = useState(false);
    const [campaign, setCampaign] = useState();
    const [selectedClient, setClient] = useState([]);
    const [options, setOptions] = useState([]);


    useEffect(() => {
        if (campaign?.id) {
            loadReportItems(campaign.id);
        }
    }, [campaign]);
    useEffect(() => {
        loadClients();
    }, []);

    let loadClients = function () {
        reportService.clientsList()
            .then(response => {
                debugger;
                console.log(response);
                const results = [];
                response.forEach((value) => {
                    results.push({
                        key: value.clientId,
                        value: value.clientName,
                    });
                });
                // Update the options state
                setOptions([
                    { key: 'Select a company', value: '' },
                    ...results
                ])

                console.log(results);
            })
            .catch(ex => {
                console.log(ex);
            });
    }

    function loadReportItems(campaignId) {
        reportService.getReportsByCampaignId(campaignId)
            .then(response => {
                if (response && response.length > 0) {
                    setCampaignReports(response);
                }
            })
            .catch(ex => {
                console.log(ex);
            });
    }
    // Dialog Handler
    const handleDialogOk = () => {
        setOpen(false);
        // formik.resetForm();
    };
    const handleClientChange = (e, callBackFunc) => {
        setClient(e.target.value);
        formik.values.clientId=e.target.value;
        callBackFunc();
        const spans = document.getElementById('clientError');
        spans.textContent = '';
    }

    const formik = useFormik({
        initialValues: {
            Id: campaign?.id,
            campaignName:'',
            createdBy: '',
            clientId: '',
            createdOn: null,
            modifiedBy: '',
            modifiedOn: null
        },
        validationSchema,
        onSubmit: values => {
            debugger;
            campaignService.create({
                Id: '',
                campaignName: values.campaignName,
                clientId: selectedClient,
                createdBy: '',
                createdOn: null,
                modifiedBy: '',
                modifiedOn: null

            })
                .then(response => {
                    if (response != null) {
                        localStorage.setItem('campaign', JSON.stringify(response))
                        formik.values.campaignName = response.campaignName;
                        setCampaign(response);
                        setOpen(true);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    })

    // add item handler
    const handleAddItem = (addingData) => {

        reportService.postFile(addingData.formData).then(response => {
            if (response != null) {
                console.log("Report Item:", response);
                debugger;
                setCampaignReports([
                    ...campaignReports,
                    {
                        id: response.id,
                        reportTitle: response.reportTitle,
                        menuTitle: response.menuTitle,
                        reportType: response.chartType,
                        campaignId: response.campaignId
                        // formData: addingData.formData,
                        // amount: addingData.amount,
                        // total: addingData.totalAmount
                    }
                ]);
            }
        }).catch(ex => {
            console.log(ex);
        });


        setAddItemClicked(false);
    };


    // to delete row in order details
    const deleteReportHandler = (id) => {

        if (confirm('Are you sure, do you want to delete the report?')) {

            reportService.deleteReportById(id)
                .then(resp => {
                    if (resp != null) {
                        openSnackbar({
                            open: true,
                            message: 'Report deleted successfully!',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        });
                        loadReportItems(campaign.id);
                        setCampaignReports(campaignReports.filter((item) => item.id !== id));


                    }
                }).catch(ex => {
                    console.log(ex);
                });

        }

    };

    return (
        <>
            <MainCard title="Create Campaign">
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6} md={5}>
                            <Stack>
                                <InputLabel required>Campaign Name</InputLabel>
                                <TextField
                                    fullWidth
                                    id="campaignName"
                                    name="campaignName"
                                    value={formik.values.campaignName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.campaignName && Boolean(formik.errors.campaignName)}
                                    helperText={formik.touched.campaignName && formik.errors.campaignName}
                                    placeholder="Campaign name here"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Stack>
                                <InputLabel required>Client Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedClient}
                                    onBlur={formik.handleBlur}
                                    // error={formik.touched.campaignId && Boolean(formik.errors.campaignId)}
                                    helperText={formik.touched.clientId && formik.errors.clientId}
                                    onChange={(e) => handleClientChange(e, formik.handleChange)}
                                >
                                    {options.map(opt => <MenuItem key={opt.key} value={opt.key}> {opt.value}</MenuItem>)}
                                </Select>
                                {formik.touched.clientId && formik.errors.clientId && (
                                    <span id='clientError' className='text-red-400'>{formik.errors.clientId}</span>
                                )}
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            {
                                campaign ? (
                                    <></>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                                <AnimateButton>
                                                    <Button variant="contained" type="submit">
                                                        Add Campaign
                                                    </Button>
                                                </AnimateButton>
                                                <AnimateButton>
                                                    <Button variant="contained" type="button">
                                                        Cancel
                                                    </Button>
                                                </AnimateButton>
                                            </Stack>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>

                        <Grid item sx={12}>
                            <Divider />
                        </Grid>

                        {campaignReports.length > 0 ? (
                            <CampaignItemPage campaignReports={campaignReports} deleteReportHandler={deleteReportHandler} />
                        ) : (<></>)
                        }

                        {addItemClicked ? (
                            <Grid item xs={12}>
                                <CreateCampaignReport campaignId={campaign?.id} handleAddItem={handleAddItem} setAddItemClicked={setAddItemClicked} />
                            </Grid>
                        ) : (
                            campaign ? (< Grid item>
                                <Button variant="text" onClick={() => setAddItemClicked(true)}>
                                    + Add Report Item
                                </Button>
                            </Grid>) : (<></>)


                        )}



                        <Grid item>
                            <Dialog open={open}>
                                <DialogContent>
                                    <DialogContentText sx={{ fontWeight: 500, color: `secondary.dark` }}>
                                        Campaign Created Successfully!
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ pr: '20px' }}>
                                    <Button autoFocus variant="contained" onClick={handleDialogOk}>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>

        </>)

}

export default CreateCampaign;