import { authenticationService } from '../services';

export function handleResponse(response) {
    return new Promise((resolve, reject) => {
        const data = response.document;
        if (response.code == 0) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                //location.reload(true);
            }
   
            const error = response && response.message;
            reject(error);
        }

        resolve(data);
    });
}
