import PropTypes from 'prop-types';

// material-ui
import { Button, FormControl, Grid, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import * as XLSX from "xlsx";
import { reportService } from "services";

import { useParams, Link } from "react-router-dom";

const CreateCampaignReport = ({ campaignId, handleAddItem, setAddItemClicked }) => {
    const { id } = useParams();
    const [reportData, setReportData] = React.useState();
    const [inputs, setInputs] = useState({});
    const [campaign, setCampaign] = useState(JSON.parse(localStorage.getItem('campaign')));
    const [selectedReportType, setSelectedReportType] = useState({
        id: 1,
        chartType: 'CrossTab',
        desc: 'Cross Tab'
    });
    const [selectedReportTitle, setSelectedReportTitle] = useState('');
    const [selectedMenuTitle, setSelectedMenuTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState();

    const [errors, setErrors] = useState({
        reportTitleError: ''
    });

    const fileRef = useRef();
    const handleChange = (event) => {
        const value = event.target.value;

        if (event.target.name === 'reportTitle') {
            if (value === '') {
                setErrors({
                    ...errors,
                    reportTitleError: 'empty title not allowed'
                });
                setSelectedReportTitle(value);
            }
            else {
                setErrors({
                    ...errors,
                    reportTitleError: ''
                });
                setSelectedReportTitle(value);
            }
        } else if (event.target.name === 'menuTitle') {
            if (value === '') {
                setErrors({
                    ...errors,
                    menuTitleError: 'empty menu not allowed'
                });
                setSelectedMenuTitle(value);
            }
            else {
                setErrors({
                    ...errors,
                    menuTitleError: ''
                });
                setSelectedMenuTitle(value);
            }
        } else if (event.target.name === 'upload') {
            if (event.target.files === null) {
                setErrors({
                    ...errors,
                    uploadError: 'file is required'
                });
                setSelectedFile(event.target.files);
            }
            else {
                setErrors({
                    ...errors,
                    uploadError: ''
                });

                handelUploadClick(event.target.files);

                setSelectedFile(event.target.files);

            }
        }
        else {
            const selectedOption = itemList.find((item) => item.id === value);
            setSelectedReportType(selectedOption);
        }
    };

    const handleOk = () => {
        const data = {
            ...reportData,
            reportTitle: selectedReportTitle,
            menuTitle: selectedMenuTitle,
            reportType: selectedReportType.chartType
        };
        handleAddItem(data);
    };

    // = (e) =>
    function handelUploadClick(targetFiles) {
        let report = {
            formData: new FormData()
        }

        let graphData = [];
        if (targetFiles) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { blankrows: true });
                debugger;
                //New function to get the report based on type
                if (selectedReportType.chartType === "CrossTab")
                    graphData = getCrossTabData(json);
                else
                    graphData = getOverAllTabData(json);

                //post file to server and get the path.
                // const fileData = new FormData();
                // fileData.append('formFile', targetFiles[0]);
                // fileData.append('fileName', targetFiles[0].name);

                report.formData.append('formFile', targetFiles[0]);
                report.formData.append('fileName', targetFiles[0].name);
                report.formData.append('reportTitle', selectedReportTitle);
                report.formData.append('menuTitle', selectedMenuTitle);
                report.formData.append('reportType', selectedReportType.chartType);
                report.formData.append('reportJsonData', JSON.stringify(graphData));
                report.formData.append('campaignId', campaignId);

                setReportData(report);
            };
            reader.readAsArrayBuffer(targetFiles[0]);

        }

        return report;

    }

    function getOverAllTabData(json) {
        json.splice(0, 0, {});
        let graphData = [];
        let questionData = [];
        // console.log(json);
        let isQuestionEnd = false;

        let tempamChartData = [];
        let isFirstQuestion = true;
        let jsonLength = json.length;
        for (let index = 0; index <= jsonLength; index++) {
            const element = json[index];
            // console.log(element);

            if (index === jsonLength) { isQuestionEnd = true; }
            else if (index > 0 && Object.keys(element).length === 0) { isQuestionEnd = true; }


            if (isQuestionEnd) {
                if (tempamChartData.length > 0) {
                    //prepare question data here
                    questionData = prepareQuestionOverAll(tempamChartData, isFirstQuestion);
                    graphData.push(questionData);
                    tempamChartData = [];
                    isQuestionEnd = false;
                    isFirstQuestion = false;
                }
            }
            else {
                tempamChartData.push(element);
            }

        }// end of data for loop
        return graphData;
    }
    function prepareQuestionOverAll(data, isFirstQuestion) {
        if (isFirstQuestion === true)
            data.splice(0, 1);
        let valueNames = [];// Object.values(data[1]);
        let categoryData = [];
        let totalValue = parseFloat(0);
        totalValue = parseFloat(Object.values(data[data.length - 1])[1]);

        if (isNaN(totalValue) === true || totalValue === undefined || totalValue == null || totalValue === "") {
            totalValue = 0;
            for (let item = 2; item < data.length - 1; item++) {
                const val = Object.values(data[item])[1];
                if (isNaN(val) === true)
                    totalValue = totalValue + parseFloat(0);
                else
                    totalValue = totalValue + parseFloat(val);
            }
        }
        if (isNaN(totalValue))
            totalValue = 0;


        for (let index = 2; index < data.length - 1; index++) {
            const element = data[index];
            let t = {};
            const jelement = Object.values(element);
            t["Name"] = jelement[0];
            t["Value"] = parseFloat(jelement[1]);
            if (totalValue === 0)
                t["ValuePerc"] = 0.0;
            else
                t["ValuePerc"] = ((parseFloat(jelement[1]) / totalValue) * 100).toFixed(2);
            categoryData.push(t);
        }

        let questionData = { title: Object.values(data[0])[0], name: Object.values(data[1])[0], data: categoryData, valueFields: valueNames, responses: totalValue };
        return questionData
        // console.log(questionData);

    }

    function getCrossTabData(json) {
        debugger;
        // json.splice(0, 0, {});
        let graphData = [];
        let questionData = [];
        // console.log(json);
        let isQuestionEnd = false;
        let jsonLength = json.length;
        let tempamChartData = [];
        let isFirstQuestion = true;
        for (let index = 0; index <= jsonLength; index++) {
            const element = json[index];
            // console.log(element);

            if (index === jsonLength) { isQuestionEnd = true; }
            else if (index > 0 && Object.keys(element).length === 0) { isQuestionEnd = true; }

            if (isQuestionEnd) {
                if (tempamChartData.length > 0) {
                    //prepare question data here
                    questionData = prepareQuestion(tempamChartData, isFirstQuestion);
                    graphData.push(questionData);
                    tempamChartData = [];
                    isQuestionEnd = false;
                    isFirstQuestion = false;
                }
            }
            else {
                tempamChartData.push(element);
            }

        }// end of data for loop
        return graphData;
    }

    function prepareQuestion(data, isFirstQuestion) {
        let qTitle = Object.values(data[0])[0];
        // if (isFirstQuestion === false)
        data.splice(0, 1);
        let valueNames = Object.values(data[1]);
        // console.log('databefore', data);
        let categoryData = [];
        let tempTotalReponses = parseFloat(0);
        let totalReponses = parseFloat(0);
        tempTotalReponses = parseFloat(calculateTotalCrossTab(Object.values(data[data.length - 1])));

        for (let index = 2; index < data.length - 1; index++) {
            const element = data[index];
            let t = {};
            let totalValue = 1;
            for (let j = 0; j < Object.values(element).length; j++) {
                const jelement = Object.values(element);
                if (j === 0) {
                    t["Category"] = jelement[j];

                    let tt = jelement.reduce((a, v) => {

                        let ta = 0, tv = 0;
                        if (isNaN(parseFloat(a)) === false)
                            ta = parseFloat(a);

                        if (isNaN(parseFloat(v)) === false)
                            tv = parseFloat(v);

                        return a = ta + tv;
                        // if (isNaN(parseInt(a)) === false && isNaN(parseInt(v)) === false)
                        //     return a = parseInt(a) + parseInt(v);
                        // else
                        //     return 0;
                    }
                        , 0);
                    totalValue = tt;
                    if (isNaN(tempTotalReponses) || tempTotalReponses === 0)
                        totalReponses = totalReponses + tt;
                    else
                        totalReponses = tempTotalReponses;
                }
                else {
                    t[valueNames[j]] = isNaN(parseInt(jelement[j])) ? 0 : parseInt(jelement[j]);
                    totalValue = (totalValue === 0) ? 1 : totalValue;
                    t[valueNames[j] + 'Perc'] = isNaN(((parseFloat(jelement[j]) / totalValue) * 100).toFixed(2)) ? 0 : ((parseFloat(jelement[j]) / totalValue) * 100).toFixed(2);
                }

            }
            categoryData.push(t);
        }

        let questionData = { title: qTitle, name: Object.values(data[0])[0], data: categoryData, valueFields: valueNames, responses: totalReponses };

        console.log(questionData);
        return questionData

    }
    function calculateTotalCrossTab(jelement) {
        let tt = jelement.reduce((a, v) => {

            let ta = 0, tv = 0;
            if (isNaN(parseInt(a)) === false)
                ta = parseInt(a);

            if (isNaN(parseInt(v)) === false)
                tv = parseInt(v);
            return a = ta + tv;
        }
            , 0);
        return tt;
    }

    const itemList = [
        {
            id: 1,
            chartType: 'CrossTab',
            desc: 'Cross Tab'
        },
        {
            id: 2,
            chartType: 'OverAll',
            desc: 'Over All'
        }
    ];
    return (<>

        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={3} sm={3}>
                <Stack spacing={1}>
                    <Typography variant="subtitle1">Report Type</Typography>
                    <FormControl>
                        <Select
                            fullWidth
                            displayEmpty
                            value={selectedReportType?.id || ''}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <Typography color="textSecondary" sx={{ lineHeight: '1.4375em' }}>
                                            Select Report Type
                                        </Typography>
                                    );
                                }

                                const selectedData = itemList.filter((item) => item.id === selected)[0];

                                return (
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                                        <Typography variant="subtitle1" sx={{ lineHeight: '1.4375em' }}>
                                            {selectedData.desc}
                                        </Typography>
                                    </Stack>
                                );
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <Typography color="textSecondary">Select Report</Typography>
                            </MenuItem>
                            {itemList.map((item, i) => (
                                <MenuItem key={i} value={item.id}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                                        <Typography variant="subtitle1">{item.desc}</Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
                <Stack spacing={1}>
                    <Typography variant="subtitle1" id="itemReportTitle">
                        Report Title
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="reportTitle"
                        value={selectedReportTitle}
                        onChange={handleChange}
                        error={Boolean(errors.reportTitleError)}
                        helperText={errors.reportTitleError}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
                <Stack spacing={1}>
                    <Typography variant="subtitle1" id="itemMenuTitle">
                        Menu Title
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="menuTitle"
                        value={selectedMenuTitle}
                        onChange={handleChange}
                        error={Boolean(errors.menuTitleError)}
                        helperText={errors.menuTitleError}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
                <Stack spacing={1}>
                    <Typography variant="subtitle1" id="itemAmount">
                        Upload File
                    </Typography>
                    {/* <input
                        type="file"
                        name="upload"
                        id="upload"
                        ref={fileRef}
                        onChange={handleFileChange}
                        multiple={false}
                    /> */}
                    <TextField
                        type="file"
                        name="upload"
                        id="upload"
                        ref={fileRef}
                        // onChange={handleFileChange}
                        onChange={handleChange}
                        multiple={false}
                    />
                </Stack>
            </Grid>
            <Grid item container justifyContent="flex-end">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Button color="error" onClick={() => setAddItemClicked(false)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!selectedReportType?.id || !selectedReportTitle || !selectedMenuTitle
                            || Boolean(errors.reportTitleError || Boolean(errors.reportMenuError
                                || !selectedFile))}
                        variant="contained"
                        size="small"
                        onClick={handleOk}
                    >
                        Add
                    </Button>
                </Stack>
            </Grid>
        </Grid>


    </>);

}

export default CreateCampaignReport;