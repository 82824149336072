import apiHelper from '../../utils/ApiHelper';
import { authHeader, handleResponse, handleError } from '../../utils';

export const reportService = {
    postFile,
    createReportData,
    getAll,
    getCampaigns,
    getReportsByCampaignId,
    deleteReportById,
    getAllSurveyResultByID,
    getReportsById,
    updateReportDRJsonData,
    sendMailReport,
    clientsList
}
const controllerName = 'Report';

function getAllSurveyResultByID(id) {
    return apiHelper.get(APIURLConstants.GET_ALLRESULT_BY_ID(id), authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function postFile(formData) {
    return apiHelper.postFormData('/' + controllerName + '/postfile', formData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function createReportData(postData) {
    return apiHelper.post('/' + controllerName + '/createreportdata', postData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}


function sendMailReport(ids, subject, mailbody, attachedFile) {
    var sendMail = {
        ids: ids,
        subjectLine: subject,
        mailBody: mailbody,
        attachmentPath: attachedFile
    }
    return apiHelper.post('/' + controllerName + '/SendMail',sendMail, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function getAll() {
    return apiHelper.get('/'+controllerName+'/get', authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function clientsList() {
    return apiHelper.get('/ClientInfo/getclients', authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function getReportsByCampaignId(campaignId) {
    return apiHelper.get('/'+controllerName+'/getReportsByCampaignId?campaignId=' + campaignId, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function getCampaigns() {
    return apiHelper.get('/'+controllerName+'/getcampaigns', authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function deleteReportById(id) {
    return apiHelper.delete('/'+controllerName+'/delete?id=' + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function getReportsById(reportId) {
    return apiHelper.get('/'+controllerName+'/getReportsById?reportId='+reportId, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function updateReportDRJsonData(reportData) {
    return apiHelper.post('/' + controllerName + '/updateReportDRJsonData', reportData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
export default reportService;