import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CreateCampaign from 'views/campaigns/CreateCampaign';
import ReportContainer from 'views/pages/report/report-container';
import CampaignReportContainer from 'views/campaigns/campaign-report/CampaignReportContainer';
import EditCampaign from 'views/campaigns/EditCampaign';
import EditClient from 'views/clients/EditClient';
import SendEMail from 'views/campaigns/send-email';
import CreateClient from 'views/campaigns/CreateClient';
import ClientList from 'views/clients/ClientList';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const CountryList = Loadable(lazy(() => import('views/masters/Country/index')));
const CampaignList = Loadable(lazy(() => import('views/campaigns/index')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/masters/country',
            element: <CountryList />
        },
        {
            path: '/campaigns',
            element: <CampaignList />
        },
        {
            path: '/campaigns/create-campaign',
            element: <CreateCampaign />
        },
        {
            path: '/campaigns/edit-campaign',
            element: <EditCampaign />
        },
        {
            path: '/campaigns/CampaignReportContainer',
            element: <CampaignReportContainer />
        }
        ,
        {
            path:'/campaigns/send-email',
            element:<SendEMail />
        },
        {
            path: '/campaigns/CreateClient',
            element: <CreateClient />
        },
        {
            path: '/clients/ClientList',
            element: <ClientList />
        },
        {
            path: '/clients/EditClient',
            element: <EditClient />
        }       
    ]
};

export default MainRoutes;
