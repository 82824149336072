import Axios from 'axios';
import LAYOUT_CONST from 'constant';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.interceptors.request.use((request) => {
    if (localStorage.getItem('serviceToken'))
    {
        var serviceToken = localStorage.getItem('serviceToken');
        request.headers['Authorization'] = `Bearer ${serviceToken}`;
    }
        

    return request;
});

const webAPIURL = () => {
    // let url = 'https://localhost:7127/v1/api/';
    // return url
    return LAYOUT_CONST.WEBAPI_URL;
};

const instance = Axios.create({
    withCredentials: false,
    baseURL: webAPIURL(),
    timeout: 60000
})

export default class APIHelper {

    static apiUrl() {
        return webAPIURL();
    }

    static get(url, headers={}, obj) {
        return new Promise((resolve, reject) => {
            instance
                .get(url, {headers})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static post(url, obj, headers={'Content-Type': 'application/json', Accept: 'application/json'}) {

        return new Promise((resolve, reject) => {
            instance
                .post(url, obj, {headers})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static delete(url, headers={'Content-Type': 'application/json', Accept: 'application/json'}) {
        return new Promise((resolve, reject) => {
            instance
                .delete(url,{headers})
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static postFormData(url, obj, headers = { 'Content-Type': 'multipart/form-data' }) {
        return new Promise((resolve, reject) => {
            instance
                .post(url, obj, { headers })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}