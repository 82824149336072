import { Button, Divider, Grid, Typography, Switch, InputLabel, Dialog, DialogContent, DialogContentText } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";

//assets
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import ReportAccordion from "ui-component/extended/ReportAccordion";
import { useTheme } from '@mui/material/styles';
import Question from "views/pages/report/question";
import { reportService } from "services";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AmChart from "views/pages/report/amchart-report";
import DynamicChart from "views/pages/report/dynamicChart-report";
import LAYOUT_CONST from "constant";

const icons = { FileDownloadIcon };
const CampaignReportContainer = () => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const [reportTitle, setReportTitle] = useState('');
    const [accordianData, setAccordianData] = useState();
    const [selItemId, setSelItemId] = useState('acc0');
    const [checked, switchEnable] = useState(false);
    const [customQuestion, setCustomQuestions] = useState([]);
    const [chartDataResponse,setChartData]=useState(null);
    const cmpName = searchParams.get("cmpName");
    const [open, setOpen] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
// Get report onload
    useEffect(() => {
        if (chartDataResponse != null)
            getReportData(searchParams.get("reportId"));
    }, [checked,open]);

    useEffect(() => {
        let reportId = searchParams.get("reportId");
        if (reportId != null) {
            getReportData(reportId);
        } else {
            alert('Report not found');
        }
    }, [searchParams]);

    useEffect(() => {
        let reportId = searchParams.get("reportId");
        let reportToUpdate = { id: reportId, reportDRJsonData: JSON.stringify(customQuestion) };
        if (reportId != '' && customQuestion.length > 0) {
            console.log("reportToUpdate:", reportToUpdate);
            reportService.updateReportDRJsonData(reportToUpdate)
                .then(resp => {
                    if (isUpdated) {
                        setOpen(true);
                    }
                }).catch(e => console.log(e));
        }
    }, [customQuestion]);
    function getReportData(reportId) {
        if (chartDataResponse == null) {
            reportService.getReportsById(reportId)
                .then(
                    resp => {
                        setChartData(resp);
                        renderChart(resp);

                    })
                .catch(e => console.log(e));
        }
        else {
            renderChart(chartDataResponse);
        }

    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    function renderChart(resp) {
        debugger;
        if (resp && resp !== null) {
            let arrDRCustomQuestion = (resp.reportDRJsonData == '') ? null : JSON.parse(resp.reportDRJsonData);
            localStorage.setItem("reportData", JSON.stringify(resp));
            let tempData = JSON.parse(localStorage.getItem("reportData"));
            setReportTitle(tempData?.reportTitle);
            let arrQuestion = JSON.parse(tempData.reportJsonData);
            var accordianArr = [];
            arrQuestion.map((row, index) => {
                let questionDRJsonData = null;
                if (arrDRCustomQuestion != null && arrDRCustomQuestion.length > 0) {
                    questionDRJsonData = arrDRCustomQuestion.find(cq => cq.title == row.title);
                }
                accordianArr.push(generateAccordian(row, index, (index == 0) ? true : false, questionDRJsonData));
            });

            setCustomQuestions(arrDRCustomQuestion == null ? [] : [...arrDRCustomQuestion]);
            setAccordianData(accordianArr);
        }
        else {
            //data not found
        }
    }


    function handleSelItemId(newSelItemId) {
        setSelItemId(newSelItemId);
    }

    const enableDynamic = (e, value) => {
        switchEnable(value);
    };
    function generateAccordian(row, index, defaultExpandFlag, questionDRJsonData) {
        let s = {
            id: 'acc' + index,
            defaultExpand: defaultExpandFlag,
            defaultExpandId: selItemId,
            title: (
                <>
                    <QuestionAnswerIcon fontSize="small" color="primary" sx={{ mr: 0.5 }} />
                    <Typography variant="subtitle1" color={theme.palette.mode === 'dark' ? 'white' : 'primary'}>
                        {row?.title}
                    </Typography>
                </>
            ),
            content: (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {!checked ?
                            <AmChart questionData={row} emelentId={index} role='Admin' questionDRJsonData={questionDRJsonData} addDRCustomQuestionHandler={addDRCustomQuestionHandler}></AmChart>
                            :
                            <DynamicChart questionData={row} emelentId={index} role='Admin' questionDRJsonData={questionDRJsonData} addDRCustomQuestionHandler={addDRCustomQuestionHandler}></DynamicChart>
                        }
                    </Grid>

                </Grid>
            )
        }
        return s;
    }

    const addDRCustomQuestionHandler = (questionData, reportData, graphTypeToSave) => {
        setIsUpdated(true);
        let updatedQuestion = {
            title: questionData.title,
            name: questionData.name,
            data: reportData.dataSource.data,
            graphType: graphTypeToSave,
            slice: reportData?.slice,
            formats: reportData?.formats,
            valueFields: questionData.valueFields,
            responses: questionData.responses
        };
        setCustomQuestions(customQuestion => {
            let questions = [...customQuestion];
            let qIndex = questions.findIndex(q => q.title === questionData.title);
            debugger;
            if (qIndex == -1) {
                return [...customQuestion, updatedQuestion]
            }
            else {
                questions[qIndex] = updatedQuestion;
                return questions;
            }
        }
        );
    };

    const DwonloadReportFile = () => {
        try {
            let reportId = searchParams.get("reportId");
            let url = LAYOUT_CONST.WEBAPI_URL + 'report/dwonloadreportfile?reportId=' + reportId;
            let filePath = chartDataResponse.excelPath;
            var request = new XMLHttpRequest();
            request.open("GET", url);
            request.responseType = "blob";
            request.onload = function () {
                var msie = window.navigator.userAgent.indexOf("MSIE");
                if (msie > 0) {
                    window.navigator.msSaveBlob(this.response, "myfile.xlsx");
                } else {
                    var url = window.URL.createObjectURL(this.response);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = filePath.substring(filePath.lastIndexOf("\\") + 1, filePath.length) || "download-" + $.now() + ".xlsx"
                    a.click();
                }
            }
            request.send();

        } catch (error) {
            console.log(error);
            alert('Error while downloading the file.');
        }
    }


    return (<>
        <MainCard title={reportTitle +': ('+cmpName+')'} secondary={<Button title="Click here to download excel file." onClick={DwonloadReportFile}><icons.FileDownloadIcon /></Button>}>

            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={12}>
                    <SubCard title="Click on question for data visualization">
                        <ReportAccordion expandIcon={<ArrowDropDownIcon />} data={accordianData} handleSelItemId={handleSelItemId} />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Chart is updated Successfully !
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>

    </>);
}

export default CampaignReportContainer;