import apiHelper from '../../utils/ApiHelper';
import { authHeader, handleResponse, handleError } from '../../utils';

export const campaignService = {
    getAll,
    getById,
    create,
    update,
    deleteSurvey,
    postFile,
    filterCampagins,
    campaignCount, cleintCampaign,sortCampagins
}
const controllerName = 'campaign';

function getAll(pagenate) {
    return apiHelper.post('/' + controllerName + '/getall', pagenate, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function campaignCount() {
    return apiHelper.get('/' + controllerName + '/campaignscount', authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function filterCampagins(filter) {
    return apiHelper.post('/' + controllerName + '/filtercampagins', filter, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function sortCampagins(filter) {
    return apiHelper.post('/' + controllerName + '/sortCampaigns', filter, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function getById(id) {
    return apiHelper.get('/' + controllerName + '/getById?' + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function cleintCampaign(id) {
    debugger;
    return apiHelper.get('/'+controllerName+'/clientCampaign?id=' + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

function create(campaign) {
    return apiHelper.post('/' + controllerName + '/create', campaign, authHeader())
        .then(handleResponse)
        .then(data => {
            console.log(data);
            return data;
        })
        .catch(handleError);
}

// function createClient(formData) {
//     return apiHelper.post('/ClientInfo/create', formData, authHeader())
//         .then(handleResponse)
//         .then(data => {
//             return data;
//         })
//         .catch(handleError);
// }
function update(campaign) {
    return apiHelper.post('/' + controllerName + '/create', campaign, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function deleteSurvey(id) {
    return apiHelper.delete('/' + controllerName + '/delete?id=' + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function postFile(formData) {
    return apiHelper.postFormData('/' + controllerName + '/postfile', formData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
export default campaignService;