import React, { useState, useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import '@mui/lab';

// // project imports
// // import AddItemPage from './AddItemPage';
import { gridSpacing } from 'store/constant';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { reportService } from 'services';
import CreateCampaignReport from './CreateCampaignReport';
import CampaignItemPage from './CampaignItemPage';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';


const EditCampaign = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [addItemClicked, setAddItemClicked] = useState(false);
    const [campaignReports, setCampaignReports] = useState([]);
    const theme = useTheme();
    const cmpid = searchParams.get("cmpid");
    const cmpName = searchParams.get("cmpName");
    const editTitle='Edit Campaign:('+cmpName+')';
    const dispatch = useDispatch();
    useEffect(() => {
        if (cmpid != null) {
            loadReportItems(cmpid);
        } else {
            alert('Click left menu');
        }
    }, [searchParams]);

    function loadReportItems(campaignId) {
        reportService.getReportsByCampaignId(campaignId)
            .then(response => {
                if (response && response.length > 0) {
                    setCampaignReports(response);
                }
            })
            .catch(ex => {
                console.log(ex);
            });
    }
    // add item handler
    const handleAddItem = (addingData) => {

        reportService.postFile(addingData.formData).then(response => {
            if (response != null) {
                console.log("Report Item:", response);

                setCampaignReports([
                    ...campaignReports,
                    {
                        id: response.id,
                        reportTitle: response.reportTitle,
                        menuTitle: response.menuTitle,
                        reportType: response.chartType,
                        campaignId: response.campaignId
                    }
                ]);
            }
        }).catch(ex => {
            console.log(ex);
        });

        setAddItemClicked(false);
    };


    // to delete row in order details
    const deleteReportHandler = (id) => {

        if (confirm('Are you sure, do you want to delete the report?')) {

            reportService.deleteReportById(id)
                .then(resp => {
                    if (resp != null) {
                        dispatch(openSnackbar({
                            open: true,
                            message: 'Report deleted successfully!',
                            variant: 'default',
                            autoHideDuration: 6000,
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                        );
                        loadReportItems(cmpid);
                        setCampaignReports(campaignReports.filter((item) => item.id !== id));

                    }
                }).catch(ex => {
                    console.log(ex);
                });
        }

    };

    return (<>
        <MainCard title={editTitle}>
            <Grid container spacing={gridSpacing}>
                {campaignReports.length > 0 ? (
                    <CampaignItemPage campaignReports={campaignReports} deleteReportHandler={deleteReportHandler} />
                ) : (<></>)
                }

                {addItemClicked ? (
                    <Grid item xs={12}>
                        <CreateCampaignReport campaignId={cmpid} handleAddItem={handleAddItem} setAddItemClicked={setAddItemClicked} />
                    </Grid>
                ) : (
                    cmpid ? (< Grid item>
                        <Button variant="text" onClick={() => setAddItemClicked(true)}>
                            + Add Report Item
                        </Button>
                    </Grid>) : (<></>)
                )}
            </Grid>
        </MainCard>

    </>);
}

export default EditCampaign;