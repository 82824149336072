import {
    Button,
    Divider,
    Grid,
    ListItemText,
    Typography,
    ListItemButton,
    Switch
} from "@mui/material";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useEffect, useState, useRef } from 'react';
import * as WebDataRocksReact from 'react-webdatarocks';
import ColorTabs from 'ui-component/basic/UITabs/ColorTabs';
import { useTheme } from '@mui/material/styles';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import RadarIcon from '@mui/icons-material/Radar';
import countryMapping from "./countryChatData";
// import countryMapping from "countryChatData";
// import * as am5 from "@amcharts/amcharts5";
// import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const icons = { EqualizerIcon, AutoGraphIcon, ShowChartIcon, RadarIcon };

const AmChart = ({ questionData, emelentId, role = 'View', questionDRJsonData, addDRCustomQuestionHandler }) => {
    console.log(questionData);
    console.log(questionDRJsonData);
    const theme = useTheme();
    // const [pivot, setPivot] = useState({});
    const [showTable, setShowTable] = useState(false);
    const [graphType, setGraphType] = useState((questionDRJsonData?.graphType == undefined) ? 'bar' : questionDRJsonData.graphType);
    let graphTypeToSave = useRef('bar');
    const gridid = 'grid' + emelentId;
    const chartid = 'barChartContainer' + emelentId;
    var pivot = null;
    var chart = null;
    var root = null;
    var customChartHandler = null;
    const exportSeeting = {
        enabled: true,
        buttons: {
            contextButton: {
                enabled: false,
                menuItems: [
                    { text: "Download Image (PNG)" },
                    { text: "Download PDF Document" },
                    { text: "Export as CSV" },
                    { separator: true },
                    { text: "Show Labels" }
                ]
            }
        },
        filename: "REDACTED",
        csv: { dateFormat: "%Y-%m-%dT%H:%M:%S-04:00" }
    }
    var mapChartData = [];
    if (questionData.data != null) {
        questionData.data.forEach((el, i) => {
            countryMapping.forEach((element, index) => {
                if (element.name === el.Name) {
                    var matchRecord = {
                        "id": element.id,
                        "name": el.Name,
                        "value": el.Value
                    }
                    mapChartData.push(matchRecord);
                }
            });
        });
    }
    useEffect(() => {
        prepareQuestion();
        Highcharts.setOptions({
            plotOptions: {
                series: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
    }, [graphType]);

    function prepareQuestion() {
        debugger;
        pivot = new WebDataRocks({
            container: '#' + gridid,
            toolbar: true,
            beforetoolbarcreated: customizeToolbar,
            report: {
                dataSource: {
                    data: (questionDRJsonData == undefined) ? questionData.data : questionDRJsonData.data
                },
                slice: questionDRJsonData?.slice,
                formats: questionDRJsonData?.formats
            },
            reportcomplete: function () {
                pivot.amcharts.getData({}, function (chartData, rawData) {
                    if (questionDRJsonData != null && questionDRJsonData != undefined) {
                        switch (questionDRJsonData?.graphType) {
                            case 'bar':
                                customChartHandler.barGraphHandeler();
                                break;
                            case 'column':
                                customChartHandler.columnGraphHandeler();
                                break;
                            case 'line':
                                customChartHandler.lineGraphHandeler();
                                break;
                            case 'pie':
                                customChartHandler.pieGraphHandeler();
                                break;
                            case 'bubble':
                                customChartHandler.bubbleGraphHandeler();
                                break;
                            case 'semicircle':
                                customChartHandler.semiCirclePieChartHandler();
                                break;
                            case 'columnwithrotate':
                                customChartHandler.columnWithRotateChartHandeler();
                                break;
                            case 'pictorial':
                                customChartHandler.pictorialStakeChatHandler();
                                break;
                            case 'variableradious':
                                customChartHandler.VariableRadiusPieChartHandler();
                                break;
                            case 'riskheatmap':
                                customChartHandler.RiskHeatmapChartHandler();
                                break;
                            case 'columnandline':
                                customChartHandler.columnAndLineMixChartHandler();
                                break;
                            case 'map':
                                customChartHandler.mapwithBabbleChartHandler();
                                break;

                            default:
                                break;
                        }
                    }
                    else
                        customChartHandler.barGraphHandeler();
                });
            },
        })
    }

    function customizeToolbar(toolbar) {
        pivot.toolbar = toolbar;
        pivot.toolbar.saveHandler = function (element) {
            addDRCustomQuestionHandler(questionData, webdatarocks.getReport(), graphTypeToSave.current);
        };
        showHideTabs(toolbar);
    }

    function showHideTabs(toolbar) {
        var tabs = toolbar.getTabs();
        toolbar.getTabs = function () {

            // Remove options from WebDatarock connect menu
            // To remote csv.
            // To remote json
            delete tabs[0].menu[2]
            delete tabs[0].menu[3]
            if (role == 'View')
                delete tabs[2]; // delete the first tab
            debugger;
            tabs.unshift({
                id: "wdr-tab-graphtype",
                title: "Graph Type",
                handler: graphtypeHandler,
                icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AutoGraphIcon" tabindex="-1" title="AutoGraph"><path d="M14.06 9.94 12 9l2.06-.94L15 6l.94 2.06L18 9l-2.06.94L15 12l-.94-2.06zM4 14l.94-2.06L7 11l-2.06-.94L4 8l-.94 2.06L1 11l2.06.94L4 14zm4.5-5 1.09-2.41L12 5.5 9.59 4.41 8.5 2 7.41 4.41 5 5.5l2.41 1.09L8.5 9zm-4 11.5 6-6.01 4 4L23 8.93l-1.41-1.41-7.09 7.97-4-4L3 19l1.5 1.5z"></path></svg>`,
                menu: [

                    { title: 'Bar', id: "wdr-tab-graph-bar", handler: customChartHandler.barGraphHandeler, icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AlignHorizontalLeftIcon" tabindex="-1" title="AlignHorizontalLeft"><path d="M4 22H2V2h2v20zM22 7H6v3h16V7zm-6 7H6v3h10v-3z"></path></svg>` },
                    { title: 'Line', id: "wdr-tab-graph-bar", handler: customChartHandler.lineChartHandler, icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TimelineIcon"><path d="M23 8c0 1.1-.9 2-2 2-.18 0-.35-.02-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02 8.35 19 8.18 19 8c0-1.1.9-2 2-2s2 .9 2 2z"></path></svg>` },
                    { title: 'Column with rotate', id: "wdr-tab-graph-column", handler: customChartHandler.columnWithRotateChartHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z"></path></svg>` },
                    { title: 'Pictorial Stake', id: "wdr-tab-graph-column", handler: customChartHandler.pictorialStakeChatHandler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ManIcon"><path d="M14 7h-4c-1.1 0-2 .9-2 2v6h2v7h4v-7h2V9c0-1.1-.9-2-2-2z"></path><circle cx="12" cy="4" r="2"></circle></svg>` },
                    { title: 'Variable radius pie chart', id: "wdr-tab-graph-column", handler: customChartHandler.VariableRadiusPieChartHandler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DataUsageIcon"><path d="M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.48 2.54l2.6 1.53c.56-1.24.88-2.62.88-4.07 0-5.18-3.95-9.45-9-9.95zM12 19c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92V2.05c-5.06.5-9 4.76-9 9.95 0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53C16.17 17.98 14.21 19 12 19z"></path></svg>` },
                    { title: 'Column', id: "wdr-tab-graph-column", handler: customChartHandler.columnGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z"></path></svg>` },
                    { title: 'Bubble', id: "wdr-tab-graph-bubble", handler: customChartHandler.bubbleGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><circle cx="7.2" cy="14.4" r="3.2"/> <circle cx="14.8" cy="18" r="2"/> <circle cx="15.2" cy="8.8" r="4.8"/></svg>` },
                    { title: 'Pie chart', id: "wdr-tab-graph-pie", handler: customChartHandler.pieGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"></path></svg>` },
                    { title: 'Semi Circle Pie chart', id: "wdr-tab-graph-pie", handler: customChartHandler.semiCirclePieChartHandler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LooksIcon"><path d="M12 10c-3.86 0-7 3.14-7 7h2c0-2.76 2.24-5 5-5s5 2.24 5 5h2c0-3.86-3.14-7-7-7zm0-4C5.93 6 1 10.93 1 17h2c0-4.96 4.04-9 9-9s9 4.04 9 9h2c0-6.07-4.93-11-11-11z"></path></svg>` },
                    { title: 'Risk Heatmap', id: "wdr-tab-graph-pie", handler: customChartHandler.RiskHeatmapChartHandler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="GridOnIcon"><path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z"></path></svg>` },
                    // { title: 'Column-Line', id: "wdr-tab-graph-pie", handler: customChartHandler.columnAndLineMixChartHandler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LegendToggleIcon"><path d="M20 15H4v-2h16v2zm0 2H4v2h16v-2zm-5-6 5-3.55V5l-5 3.55L10 5 4 8.66V11l5.92-3.61L15 11z"></path></svg>` },
                    { title: 'Hybrid Drill-Down Pie', id: "wdr-tab-graph-hybrid", handler: customChartHandler.hybridGraphHandeler, icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PieChartOutlineIcon"><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 2.07c3.61.45 6.48 3.33 6.93 6.93H13V4.07zM4 12c0-4.06 3.07-7.44 7-7.93v15.87c-3.93-.5-7-3.88-7-7.94zm9 7.93V13h6.93c-.45 3.61-3.32 6.48-6.93 6.93z"></path></svg>` },
                    { title: 'Map-Chart', id: "wdr-tab-graph-pie", handler: customChartHandler.mapwithBabbleChartHandler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"></path></svg>` },

                ]
            });

            return tabs;
        }
        customChartHandler = {
            disposeChart: function () {
                if (chart != null) {
                    chart.dispose();
                    pivot.off("reportcomplete");
                }
            },
            hybridGraphHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, hybrid, hybrid);
                function hybrid(chartData, rawData) {
                    graphTypeToSave.current = 'hybrid';
                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);

                    /* Create chart instance */
                    chart = am4core.create(chartid, am4core.Container);
                    chart.width = am4core.percent(100);
                    chart.height = am4core.percent(100);
                    chart.layout = "horizontal";
                    let columnChart = chart.createChild(am4charts.XYChart);
                    console.log(rawData);
                    console.log(chartData);

                    // Create axes
                    var categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.inversed = true;

                    var valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

                    // Create series
                    var columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
                    columnSeries.dataFields.valueX = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    columnSeries.dataFields.categoryY = pivot.amcharts.getCategoryName(rawData);
                    columnSeries.columns.template.strokeWidth = 0;

                    let pieChart = chart.createChild(am4charts.PieChart);
                    pieChart.data = chartData.data;
                    pieChart.innerRadius = am4core.percent(50);

                    // Add and configure Series
                    var pieSeries = pieChart.series.push(new am4charts.PieSeries());
                    pieSeries.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    pieSeries.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    pieSeries.slices.template.propertyFields.fill = "color";
                    pieSeries.labels.template.disabled = true;

                    // Set up labels
                    var label1 = pieChart.seriesContainer.createChild(am4core.Label);
                    label1.text = "";
                    label1.horizontalCenter = "middle";
                    label1.fontSize = 35;
                    label1.fontWeight = 600;
                    label1.dy = -30;

                    var label2 = pieChart.seriesContainer.createChild(am4core.Label);
                    label2.text = "";
                    label2.horizontalCenter = "middle";
                    label2.fontSize = 12;
                    label2.dy = 20;

                    // Auto-select first slice on load
                    pieChart.events.on("ready", function (ev) {
                        debugger;
                        pieSeries.slices.getIndex(0).isActive = true;
                    });

                    // Set up toggling events
                    pieSeries.slices.template.events.on("toggled", function (ev) {
                        if (ev.target.isActive) {

                            // Untoggle other slices
                            pieSeries.slices.each(function (slice) {
                                if (slice != ev.target) {
                                    slice.isActive = false;
                                }
                            });


                            // Update column chart
                            columnSeries.appeared = false;
                            columnChart.data = chartData.data; // ev.target.dataItem.dataContext.category;
                            columnSeries.fill = ev.target.fill;
                            columnSeries.reinit();

                            // Update labels
                            label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
                            label1.fill = ev.target.fill;

                            label2.text = ev.target.dataItem.category;
                        }
                    });


                }

            },
            barGraphHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, bar, bar);
                function bar(data, rawData) {
                    graphTypeToSave.current = 'bar';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }

                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);

                    /* Create chart instance */
                    chart = am4core.create(chartid, am4charts.XYChart);

                    /* Add data processed by WebDataRocks to the chart */
                    chart.data = chartData.data;
                    var numberFormat = pivot.amcharts.getNumberFormatPattern(rawData.meta.formats[0]);

                    /* Create axes */
                    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = pivot.amcharts.getCategoryName(rawData);

                    /* Apply number formatting to the chart */
                    categoryAxis.numberFormatter.numberFormat = numberFormat;
                    categoryAxis.renderer.inversed = true;
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.cellStartLocation = 0.1;
                    categoryAxis.renderer.cellEndLocation = 0.9;


                    //Set the category label width
                    let label = categoryAxis.renderer.labels.template;
                    label.wrap = true;
                    label.maxWidth = 140;
                    label.truncate = true;

                    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.opposite = true;

                    /* Create and configure series for a clustered bar chart */
                    for (let s = 0; s < pivot.amcharts.getNumberOfMeasures(rawData); s++) {
                        var series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueX = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.dataFields.categoryY = pivot.amcharts.getCategoryName(rawData);
                        series.name = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
                        series.columns.template.height = am4core.percent(100);
                        series.sequencedInterpolation = true;
                        series.columns.template.column.cornerRadiusBottomRight = 10;
                        series.columns.template.column.cornerRadiusTopRight = 10;
                        series.columns.template.column.fillOpacity = 0.8;
                        series.columns.template.adapter.add("fill", function (fill, target) {
                            return chart.colors.getIndex(target.dataItem.index);
                        });


                        var bullet = series.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;


                        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
                        valueLabel.label.text = "{valueX}";
                        valueLabel.label.horizontalCenter = "left";
                        valueLabel.label.dx = 10;
                        valueLabel.label.hideOversized = false;
                        valueLabel.label.truncate = false;

                    }

                    //Setting legends here with truncate widht
                    chart.legend = new am4charts.Legend();
                    chart.legend.labels.template.maxWidth = 150;
                    chart.legend.labels.template.truncate = true;

                    // //settting scroll on graph
                    // var scrollbarY = new am4core.Scrollbar();
                    // chart.scrollbarY = scrollbarY;

                    // Set cell size in pixels
                    chart.svgContainer.htmlElement.style.minHeight = "350px";
                    //Set chart height
                    // Set cell size in pixels
                    let cellSize = 60;
                    chart.events.on("datavalidated", function (ev) {

                        // Get objects of interest
                        let chart = ev.target;
                        let categoryAxis = chart.yAxes.getIndex(0);

                        // Calculate how we need to adjust chart height
                        let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

                        // get current chart height
                        let targetHeight = chart.pixelHeight + adjustHeight;

                        // Set it on chart's container
                        chart.svgContainer.htmlElement.style.height = targetHeight + "px";
                    });

                }

            },

            lineGraphHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, line, line);
                function line(data, rawData) {
                    graphTypeToSave.current = 'line';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }

                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);

                    /* Create chart instance */
                    chart = am4core.create(chartid, am4charts.XYChart);
                    chart.paddingRight = 10;
                    /* Add data processed by WebDataRocks to the chart */
                    chart.data = chartData.data;
                    var numberFormat = pivot.amcharts.getNumberFormatPattern(rawData.meta.formats[0]);

                    /* Apply number formatting to the chart */
                    chart.numberFormatter.numberFormat = numberFormat;

                    var categoryAxis1 = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis1.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    categoryAxis1.renderer.grid.template.location = 0;
                    // categoryAxis1.renderer.labels.template.rotation = 270;
                    // categoryAxis1.renderer.minHeight = 110;
                    let label = categoryAxis1.renderer.labels.template;
                    label.wrap = true;
                    label.maxWidth = 120;
                    label.truncate = true;

                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.tooltip.disabled = true;
                    valueAxis.renderer.minWidth = 35;
                    valueAxis.renderer.axisFills.template.disabled = true;
                    valueAxis.renderer.ticks.template.disabled = true;

                    var series = chart.series.push(new am4charts.LineSeries());
                    series.dataFields.categoryX = pivot.amcharts.getCategoryName(rawData);
                    series.dataFields.valueY = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    series.strokeWidth = 3;
                    series.tensionX = 0.8;
                    series.bullets.push(new am4charts.CircleBullet());
                    series.tooltip.text = "{categoryX}: {valueY}";
                    series.propertyFields.stroke = "color";
                    series.xAxes = categoryAxis1;

                    // Enable interactions on series segments
                    let segment = series.segments.template;
                    segment.interactionsEnabled = true;

                    // Create hover state
                    var hs = segment.states.create("hover");
                    hs.properties.strokeWidth = 10;
                    chart.legend = new am4charts.Legend();

                    chart.svgContainer.htmlElement.style.minHeight = "300px";
                    var yAxes = chart.yAxes.getIndex(0);

                    // Set cell size in pixels
                    let cellSize = 30;
                    chart.events.on("datavalidated", function (ev) {

                        // Get objects of interest
                        let chart = ev.target;
                        let categoryAxis = chart.yAxes.getIndex(0);

                        // Calculate how we need to adjust chart height
                        let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

                        // get current chart height
                        let targetHeight = chart.pixelHeight + adjustHeight;

                        // Set it on chart's container
                        chart.svgContainer.htmlElement.style.height = targetHeight + "px";
                    });
                }

            },
            columnGraphHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, column, column);
                function column(data, rawData) {
                    graphTypeToSave.current = 'column';
                    if (chart != null) {
                        chart.dispose();
                    }
                    graphTypeToSave.current = 'column';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);
                    /* Create chart instance */
                    chart = am4core.create(chartid, am4charts.XYChart);
                    chart.hiddenState.properties.opacity = 0;

                    /* Add data processed by WebDataRocks to the chart */
                    chart.data = chartData.data;
                    /* Create axes */

                    /* Category axis */
                    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.minGridDistance = 30;
                    //Set the category label width
                    let label = categoryAxis.renderer.labels.template;
                    label.wrap = true;
                    label.maxWidth = 140;
                    label.truncate = true;
                    /* Value axis */
                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.numberFormatter = new am4core.NumberFormatter();
                    /* Get number formatting from WebDataRocks prepared to a format required by amCharts */
                    var numberFormat = pivot.amcharts.getNumberFormatPattern(rawData.meta.formats[0]);
                    /* Apply number formatting to the chart */
                    valueAxis.numberFormatter.numberFormat = numberFormat;

                    valueAxis.min = 0;
                    valueAxis.renderer.minGridDistance = 30;
                    valueAxis.renderer.baseGrid.disabled = true;
                    /* Create and configure series */
                    for (let s = 0; s < pivot.amcharts.getNumberOfMeasures(rawData); s++) {
                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.dataFields.categoryX = pivot.amcharts.getCategoryName(rawData);
                        series.clustered = false;
                        series.name = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.tooltipText = "{name}: [bold]{valueY}[/]";
                        // series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                        series.columns.template.column.cornerRadiusTopLeft = 10;
                        series.columns.template.column.cornerRadiusTopRight = 10;
                        series.columns.template.column.fillOpacity = 0.8;
                        series.columns.template.adapter.add("fill", function (fill, target) {
                            return chart.colors.getIndex(target.dataItem.index);
                        });

                        chart.cursor = new am4charts.XYCursor();
                        chart.cursor.lineX.disabled = true;
                        chart.cursor.lineY.disabled = true;
                    }

                    chart.svgContainer.htmlElement.style.minHeight = "300px";
                }
            },
            pieGraphHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, pie, pie);
                function pie(data, rawData) {
                    graphTypeToSave.current = 'pie';

                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);

                    /* Create chart instance */
                    chart = am4core.create(chartid, am4charts.PieChart);

                    /* Add data processed by WebDataRocks to the chart */
                    chart.data = chartData.data;
                    var numberFormat = pivot.amcharts.getNumberFormatPattern(rawData.meta.formats[0]);

                    /* Apply number formatting to the chart */
                    chart.numberFormatter.numberFormat = numberFormat;

                    /* Create and configure series for a pie chart */
                    var pieSeries = chart.series.push(new am4charts.PieSeries());
                    pieSeries.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    pieSeries.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    pieSeries.slices.template.stroke = am4core.color("#fff");
                    pieSeries.slices.template.strokeWidth = 2;
                    pieSeries.slices.template.strokeOpacity = 1;
                    pieSeries.slices.template.adapter.add("fill", function (fill, target) {
                        return chart.colors.getIndex(target.dataItem.index);
                    });
                    /* Create initial animation */
                    pieSeries.hiddenState.properties.opacity = 1;
                    pieSeries.hiddenState.properties.endAngle = -90;
                    pieSeries.hiddenState.properties.startAngle = -90;

                    chart.svgContainer.htmlElement.style.minHeight = "300px";
                }
            },
            bubbleGraphHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, babble, babble);
                function babble(data, rawData) {
                    graphTypeToSave.current = 'bubble';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);
                    /* Create chart instance */
                    chart = am4core.create(chartid, am4charts.XYChart);
                    /* Add data processed by WebDataRocks to the chart */
                    chart.data = chartData.data;
                    var valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
                    valueAxisX.renderer.ticks.template.disabled = true;
                    valueAxisX.renderer.axisFills.template.disabled = true;

                    var valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxisY.renderer.ticks.template.disabled = true;
                    valueAxisY.renderer.axisFills.template.disabled = true;

                    for (let s = 0; s < pivot.amcharts.getNumberOfMeasures(rawData); s++) {
                        let series = chart.series.push(new am4charts.LineSeries());
                        series.dataFields.valueX = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.dataFields.valueY = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        let measure1 = pivot.amcharts.getMeasureNameByIndex(rawData, s);

                        series.strokeOpacity = 0;
                        series.sequencedInterpolation = true;
                        series.tooltip.pointerOrientation = "vertical";

                        let bullet = series.bullets.push(new am4core.Circle());
                        bullet.fill = am4core.color("#ff0000");
                        bullet.propertyFields.fill = "color";
                        bullet.strokeOpacity = 0;
                        bullet.strokeWidth = 2;
                        bullet.fillOpacity = 0.5;
                        bullet.stroke = am4core.color("#ffffff");
                        bullet.hiddenState.properties.opacity = 0;
                        bullet.tooltipText = `[bold]{Name}:[/]\n ${measure1}: {valueX.value}`;


                        let outline = chart.plotContainer.createChild(am4core.Circle);
                        outline.fillOpacity = 0;
                        outline.strokeOpacity = 0.8;
                        outline.stroke = am4core.color("#ff0000");
                        outline.strokeWidth = 2;
                        outline.hide(0);

                        let blurFilter = new am4core.BlurFilter();
                        outline.filters.push(blurFilter);

                        bullet.events.on("over", function (event) {
                            let target = event.target;
                            outline.radius = target.pixelRadius + 2;
                            outline.x = target.pixelX;
                            outline.y = target.pixelY;
                            outline.show();
                        })

                        bullet.events.on("out", function (event) {
                            outline.hide();
                        });

                        let hoverState = bullet.states.create("hover");
                        hoverState.properties.fillOpacity = 1;
                        hoverState.properties.strokeOpacity = 1;

                        series.heatRules.push({ target: bullet, min: 2, max: 60, property: "radius" });

                        bullet.adapter.add("tooltipY", function (tooltipY, target) {
                            return -target.radius;
                        })
                        chart.cursor = new am4charts.XYCursor();
                        chart.cursor.behavior = "zoomXY";
                        chart.cursor.snapToSeries = series;

                        chart.scrollbarX = new am4core.Scrollbar();
                        chart.scrollbarY = new am4core.Scrollbar();

                    }

                    chart.svgContainer.htmlElement.style.minHeight = "300px";


                }
            },
            semiCirclePieChartHandler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, semi, semi);
                function semi(data, rawData) {
                    graphTypeToSave.current = 'semicircle';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    am4core.useTheme(am4themes_animated);
                    chart = am4core.create(chartid, am4charts.PieChart);
                    chart.hiddenState.properties.opacity = 0;
                    chart.data = chartData.data;
                    var numberFormat = pivot.amcharts.getNumberFormatPattern(rawData.meta.formats[0]);

                    /* Apply number formatting to the chart */
                    chart.numberFormatter.numberFormat = numberFormat;

                    /* Set set an angle for a pie chart to start on and an angle to end on */
                    chart.radius = am4core.percent(70);
                    chart.innerRadius = am4core.percent(40);
                    chart.startAngle = 180;
                    chart.endAngle = 360;


                    var series = chart.series.push(new am4charts.PieSeries());
                    series.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    series.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, 0);

                    series.slices.template.cornerRadius = 10;
                    series.slices.template.innerCornerRadius = 7;
                    series.slices.template.draggable = true;
                    series.slices.template.inert = true;
                    series.alignLabels = false;

                    series.hiddenState.properties.startAngle = 90;
                    series.hiddenState.properties.endAngle = 90;

                    chart.legend = new am4charts.Legend();
                    chart.svgContainer.htmlElement.style.minHeight = "300px";
                }
            },
            columnWithRotateChartHandeler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, columnwithRotate, columnwithRotate);
                function columnwithRotate(data, rawData) {
                    graphTypeToSave.current = 'columnwithrotate';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    /* Apply amCharts theme */
                    am4core.useTheme(am4themes_animated);
                    /* Create chart instance */
                    chart = am4core.create(chartid, am4charts.XYChart);
                    chart.hiddenState.properties.opacity = 0;

                    /* Add data processed by WebDataRocks to the chart */
                    chart.data = chartData.data;


                    /* Category axis */
                    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.minGridDistance = 30;
                    categoryAxis.renderer.labels.template.rotation = 270;
                    categoryAxis.renderer.minHeight = 110;

                    /* Value axis */
                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.minWidth = 50;
                    //Set the category label width
                    let label = categoryAxis.renderer.labels.template;
                    label.wrap = true;
                    label.maxWidth = 140;
                    label.truncate = true;

                    /* Create and configure series */
                    for (let s = 0; s < pivot.amcharts.getNumberOfMeasures(rawData); s++) {
                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.dataFields.categoryX = pivot.amcharts.getCategoryName(rawData);
                        series.clustered = false;
                        series.name = pivot.amcharts.getMeasureNameByIndex(rawData, s);
                        series.tooltipText = "{name}: [bold]{valueY}[/]";
                        series.columns.template.strokeWidth = 0;
                        series.tooltip.pointerOrientation = "vertical";
                        series.columns.template.column.cornerRadiusTopLeft = 10;
                        series.columns.template.column.cornerRadiusTopRight = 10;
                        series.columns.template.column.fillOpacity = 0.8;
                        series.columns.template.adapter.add("fill", function (fill, target) {
                            return chart.colors.getIndex(target.dataItem.index);
                        });
                        chart.cursor = new am4charts.XYCursor();
                        chart.cursor.lineX.disabled = true;
                        chart.cursor.lineY.disabled = true;

                        // Set cell size in pixels
                        let cellSize = 30;
                        chart.events.on("datavalidated", function (ev) {

                            // Get objects of interest
                            let chart = ev.target;
                            let categoryAxis = chart.yAxes.getIndex(0);

                            // Calculate how we need to adjust chart height
                            let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

                            // get current chart height
                            let targetHeight = chart.pixelHeight + adjustHeight;

                            // Set it on chart's container
                            chart.svgContainer.htmlElement.style.minHeight = targetHeight + "px";
                        });
                    }

                    //Set chart height
                    chart.svgContainer.htmlElement.style.height = "550px";

                }

            },
            pictorialStakeChatHandler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, pictorial, pictorial);
                function pictorial(data, rawData) {
                    graphTypeToSave.current = 'pictorial';
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    am4core.useTheme(am4themes_animated);
                    // Themes end

                    var iconPath = "M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z"

                    var chart = am4core.create(chartid, am4charts.SlicedChart);
                    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                    chart.data = chartData.data;

                    var series = chart.series.push(new am4charts.PictorialStackedSeries());
                    series.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    series.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    series.alignLabels = true;

                    series.maskSprite.path = iconPath;
                    series.ticks.template.locationX = 1;
                    series.ticks.template.locationY = 0.5;

                    series.labelsContainer.width = 200;

                    chart.legend = new am4charts.Legend();
                    chart.legend.position = "left";
                    chart.legend.valign = "bottom";

                };

            },
            VariableRadiusPieChartHandler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, varibleRadiusPie, varibleRadiusPie);
                function varibleRadiusPie(data, rawData) {
                    graphTypeToSave.current = 'variableradious';

                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    am4core.useTheme(am4themes_animated);

                    var chart = am4core.create(chartid, am4charts.PieChart);
                    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                    chart.data = chartData.data;
                    var series = chart.series.push(new am4charts.PieSeries());
                    series.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    series.dataFields.radiusValue = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    series.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    series.colors.step = 3;
                    series.ticks.template.disabled = true;
                    series.labels.template.disabled = true;

                    series.hiddenState.properties.endAngle = -90;

                    chart.legend = new am4charts.Legend();
                    chart.legend.position = "right";
                }
            },
            RiskHeatmapChartHandler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, RiskHeatMap, RiskHeatMap);
                function RiskHeatMap(data, rawData) {
                    graphTypeToSave.current = 'riskheatmap';

                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    // Apply chart themes
                    am4core.useTheme(am4themes_animated);

                    var chart = am4core.create(chartid, am4charts.XYChart);
                    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
                    chart.data = chartData.data;
                    chart.maskBullets = false;

                    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

                    xAxis.dataFields.category = pivot.amcharts.getCategoryName(rawData);
                    yAxis.dataFields.category = pivot.amcharts.getCategoryName(rawData);

                    xAxis.renderer.grid.template.disabled = true;
                    xAxis.renderer.minGridDistance = 40;

                    yAxis.renderer.grid.template.disabled = true;
                    yAxis.renderer.inversed = true;
                    yAxis.renderer.minGridDistance = 30;

                    var series = chart.series.push(new am4charts.ColumnSeries());
                    series.dataFields.categoryX = pivot.amcharts.getCategoryName(rawData);
                    series.dataFields.categoryY = pivot.amcharts.getCategoryName(rawData);
                    series.dataFields.value = pivot.amcharts.getMeasureNameByIndex(rawData, 0);
                    series.sequencedInterpolation = true;
                    series.defaultState.transitionDuration = 3000;

                    // Set up column appearance
                    var column = series.columns.template;
                    column.strokeWidth = 2;
                    column.strokeOpacity = 1;
                    column.stroke = am4core.color("#ffffff");
                    column.tooltipText = "{categoryX}, {categoryY}: {value.workingValue.formatNumber('#.')}";
                    column.width = am4core.percent(100);
                    column.height = am4core.percent(100);
                    column.column.cornerRadius(6, 6, 6, 6);
                    column.propertyFields.fill = "color";

                    // Set up bullet appearance
                    var bullet1 = series.bullets.push(new am4charts.CircleBullet());
                    bullet1.circle.propertyFields.radius = "value";
                    bullet1.circle.fill = am4core.color("#000");
                    bullet1.circle.strokeWidth = 0;
                    bullet1.circle.fillOpacity = 0.7;
                    bullet1.interactionsEnabled = false;

                    var bullet2 = series.bullets.push(new am4charts.LabelBullet());
                    bullet2.label.text = "{value}";
                    bullet2.label.fill = am4core.color("#fff");
                    bullet2.zIndex = 1;
                    bullet2.fontSize = 11;
                    bullet2.interactionsEnabled = false;

                    chart.svgContainer.htmlElement.style.minHeight = "300px";
                }
            },
            columnAndLineMixChartHandler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, columnAndLineMix, columnAndLineMix);
                function columnAndLineMix(gdata, rawData) {
                    graphTypeToSave.current = 'columnandline';

                    let chartData = {
                        ...gdata,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    am4core.useTheme(am4themes_animated);

                    var chart = am4core.create(chartid, am4charts.XYChart);

                    // Export
                    chart.exporting.menu = new am4core.ExportMenu();

                    // Data for both series
                    var data = [{
                        "year": "2009",
                        "income": 23.5,
                        "expenses": 21.1
                    }, {
                        "year": "2010",
                        "income": 26.2,
                        "expenses": 30.5
                    }, {
                        "year": "2011",
                        "income": 30.1,
                        "expenses": 34.9
                    }, {
                        "year": "2012",
                        "income": 29.5,
                        "expenses": 31.1
                    }, {
                        "year": "2013",
                        "income": 30.6,
                        "expenses": 28.2,
                        "lineDash": "5,5",
                    }, {
                        "year": "2014",
                        "income": 34.1,
                        "expenses": 32.9,
                        "strokeWidth": 1,
                        "columnDash": "5,5",
                        "fillOpacity": 0.2,
                        "additional": "(projection)"
                    }];
                    /* Create axes */
                    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "year";
                    categoryAxis.renderer.minGridDistance = 30;

                    /* Create value axis */
                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                    /* Create series */
                    var columnSeries = chart.series.push(new am4charts.ColumnSeries());
                    columnSeries.name = "Income";
                    columnSeries.dataFields.valueY = "income";
                    columnSeries.dataFields.categoryX = "year";

                    columnSeries.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
                    columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
                    columnSeries.columns.template.propertyFields.stroke = "stroke";
                    columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
                    columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
                    columnSeries.tooltip.label.textAlign = "middle";

                    var lineSeries = chart.series.push(new am4charts.LineSeries());
                    lineSeries.name = "Expenses";
                    lineSeries.dataFields.valueY = "expenses";
                    lineSeries.dataFields.categoryX = "year";

                    lineSeries.stroke = am4core.color("#fdd400");
                    lineSeries.strokeWidth = 3;
                    lineSeries.propertyFields.strokeDasharray = "lineDash";
                    lineSeries.tooltip.label.textAlign = "middle";

                    var bullet = lineSeries.bullets.push(new am4charts.Bullet());
                    bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
                    bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
                    var circle = bullet.createChild(am4core.Circle);
                    circle.radius = 4;
                    circle.fill = am4core.color("#fff");
                    circle.strokeWidth = 3;

                    chart.data = data;
                }
            },
            mapwithBabbleChartHandler: function () {
                customChartHandler.disposeChart();
                pivot.amcharts.getData({}, mapWithBabble, mapWithBabble);
                function mapWithBabble(chartData, rawData) {
                    am4core.ready(function () {
                        // Themes begin
                        am4core.useTheme(am4themes_animated);
                        // Themes end
                        graphTypeToSave.current = 'map';
                        // Create map instance
                        var chart = am4core.create(chartid, am4maps.MapChart);


                        var mapData = mapChartData;
                        // Set map definition
                        chart.geodata = am4geodata_worldLow;

                        // Set projection
                        chart.projection = new am4maps.projections.Miller();

                        // Create map polygon series
                        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
                        polygonSeries.exclude = ["AQ"];
                        polygonSeries.useGeodata = true;
                        polygonSeries.nonScalingStroke = true;
                        polygonSeries.strokeWidth = 0.5;
                        polygonSeries.calculateVisualCenter = true;


                        var imageSeries = chart.series.push(new am4maps.MapImageSeries());
                        imageSeries.data = mapData;
                        imageSeries.dataFields.value = "value";

                        var imageTemplate = imageSeries.mapImages.template;
                        imageTemplate.nonScaling = true

                        var circle = imageTemplate.createChild(am4core.Circle);
                        circle.fillOpacity = 0.7;
                        circle.propertyFields.fill = "color";
                        circle.fill = am4core.color("#ff0000");
                        circle.stroke = am4core.color("#ffffff");
                        circle.strokeOpacity = 0;
                        circle.strokeWidth = 2;
                        circle.hiddenState.properties.opacity = 0;
                        circle.tooltipText = "{name}: [bold]{value}[/]";


                        imageSeries.heatRules.push({
                            "target": circle,
                            "property": "radius",
                            "min": 4,
                            "max": 30,
                            "dataField": "value"
                        })

                        imageTemplate.adapter.add("latitude", function (latitude, target) {
                            var polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
                            if (polygon) {
                                return polygon.visualLatitude;
                            }
                            return latitude;
                        })

                        imageTemplate.adapter.add("longitude", function (longitude, target) {
                            var polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
                            if (polygon) {
                                return polygon.visualLongitude;
                            }
                            return longitude;
                        })



                    })
                }
            }
        }

        let graphtypeHandler = function () {
        }
    }
    function handleShowTable(value) {
        setShowTable(showTable === false ? true : false);

    }
    function handleExportGraph() {
        if (chart != null)
            chart.exportChart();
    }

    return ((questionData) && <>
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={4}>
                <Grid item>
                    <Button onClick={() => handleExportGraph(false)}
                        variant="contained"                        >
                        Export Graph
                    </Button>
                </Grid>
                <Grid item>
                    <AnimateButton
                        scale={{
                            hover: 1.1,
                            tap: 0.9
                        }}
                    >
                        <Button onClick={() => handleShowTable(false)}
                            variant="contained"
                            color="secondary"
                            sx={{
                                boxShadow: theme.customShadows.secondary,
                                ':hover': {
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            {showTable == true ? 'View Graph' : 'View Data'}
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
            <Divider sx={{ height: 10, m: 0.5, mr: 1.5 }} orientation="vertical" />
            <Grid item xs={12} sm={12}>
                <div id={'grid' + `${emelentId}`} className={showTable == false ? 'hide-div' : 'show-div'}></div>
                <div id={'barChartContainer' + `${emelentId}`} className={showTable == true ? 'hide-div' : 'show-div'}></div>
            </Grid>
        </Grid>
    </>);


}
export default AmChart;