import { Divider, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { gridSpacing } from "store/constant";
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
//assets
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Question from "./question";
import ReportAccordion from "ui-component/extended/ReportAccordion";
import { useTheme } from '@mui/material/styles';
import LAYOUT_CONST from "constant";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AmChart from "./amchart-report";
import { reportService } from "services";
const icons = { FileDownloadIcon, QuestionAnswerIcon, AnalyticsIcon };
const ReportContainer = () => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    // const [questions, setQuestions] = useState();
    const [reportTitle, setReportTitle] = useState('');
    const [filePath, setFilePath] = useState('');

    const [accordianData, setAccordianData] = useState();
    const [selItemId, setSelItemId] = useState('acc0');
    const navigate = useNavigate();


    useEffect(() => {
        let cmpid = searchParams.get("cmpid");
        let reportId = searchParams.get("reportId");
        if (cmpid != null && reportId != null) {
            getReportData(reportId);

        } else {
            alert('Click left menu');
        }
    }, [searchParams]);

    function getReportData(reportId) {

        reportService.getReportsById(reportId)
            .then(
                resp => {

                    setReportTitle(resp?.reportTitle);
                    setFilePath(resp?.excelPath);
                    let arrQuestion = JSON.parse(resp.reportJsonData);
                    let arrDRCustomQuestion = JSON.parse(resp.reportDRJsonData);
                    var accordianArr = [];
                    arrQuestion.map((row, index) => {
                        let questionDRJsonData = null;
                        if (arrDRCustomQuestion != null && arrDRCustomQuestion.length > 0) {
                            questionDRJsonData = arrDRCustomQuestion.find(cq => cq.title == row.title);
                        }
                        accordianArr.push(generateAccordian(row, index, (index == 0) ? true : false, questionDRJsonData));
                    });
                    setAccordianData(accordianArr);

                })
            .catch(e => console.log(e));
    }

    function handleSelItemId(newSelItemId) {
        setSelItemId(newSelItemId);
    }
    function generateAccordian(row, index, defaultExpandFlag, questionDRJsonData) {
        let s = {
            id: 'acc' + index,
            defaultExpand: defaultExpandFlag,
            defaultExpandId: selItemId,
            defaultExpandedId: selItemId,
            title: (
                <>
                    <QuestionAnswerIcon fontSize="small" color="primary" sx={{ mr: 0.5 }} />
                    <Typography variant="subtitle1" color={theme.palette.mode === 'dark' ? 'white' : 'primary'}>
                        {row?.title}
                    </Typography>

                </>
            ),
            content: (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {row?.name}
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Question questionData={row} emelentId={index} role='View' questionDRJsonData={questionDRJsonData}></Question> */}
                        <AmChart questionData={row} emelentId={index} role='View' questionDRJsonData={questionDRJsonData}></AmChart>

                    </Grid>

                </Grid>
            )
        }
        return s;
    }

    const DwonloadReportFile = () => {
        try {
            let reportId = searchParams.get("reportId");
            let url = LAYOUT_CONST.WEBAPI_URL + 'report/dwonloadreportfile?reportId=' + reportId;
            var request = new XMLHttpRequest();
            request.open("GET", url);
            request.responseType = "blob";
            request.onload = function () {
                var msie = window.navigator.userAgent.indexOf("MSIE");
                if (msie > 0) {
                    window.navigator.msSaveBlob(this.response, "myfile.xlsx");
                } else {
                    var url = window.URL.createObjectURL(this.response);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = filePath.substring(filePath.lastIndexOf("\\") + 1, filePath.length) || "download-" + $.now() + ".xlsx"
                    a.click();
                }
            }
            request.send();

        } catch (error) {
            console.log(error);
            alert('Error while downloading the file.');
        }
    }

    const LoadDashboard = () => {
        let cmpid = searchParams.get("cmpid");
        let reportId = searchParams.get("reportId");
        let dashboardLandingUrl = '/report/load-dashboard?cmpid=' + cmpid + '&reportId=' + reportId;
        navigate(dashboardLandingUrl);
    }

    return (<>
        <MainCard title={reportTitle} secondary={<><Button title="Click here to open dashboard." onClick={LoadDashboard}><DashboardOutlinedIcon /></Button> <Button title="Click here to download excel file." onClick={DwonloadReportFile}><icons.FileDownloadIcon /></Button></>}>

            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={12}>
                    <SubCard title="Click on question for data visualization" >
                        {/* <Accordion expandIcon={<ArrowDropDownIcon />} data={accordianData} /> */}
                        <ReportAccordion expandIcon={<ArrowDropDownIcon />} data={accordianData} handleSelItemId={handleSelItemId} />
                    </SubCard>
                </Grid>
            </Grid>
        </MainCard>

        {/* <h4>Report container</h4>
        {questions && questions.length > 0 &&
            questions.map((row, index) => {
                return (<>
                    <h3>{row.title}</h3>
                    <Question questionData={row} emelentId={index}></Question>
                </>)
            })

            // <>
            //     <h3>{questions[0].title}</h3>
            //     <Question questionData={questions[0]} emelentId={1}></Question>
            // </>
        } */}
    </>);
}

export default ReportContainer;