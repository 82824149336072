import apiHelper from '../../utils/ApiHelper';
import { authHeader, handleResponse, handleError } from '../../utils';

export const clientService = {
    getAll,
    getClient,
    getClientsCount,
    updateClient,
    createClient
}
const controllerName = 'ClientInfo';


function getAll(paging) {
    return apiHelper.post('/' + controllerName + '/getall', paging, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function createClient(formData) {
    return apiHelper.post('/'+controllerName+'/create', formData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function getClientsCount() {
    return apiHelper.get('/' + controllerName + '/getclientscount', authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function updateClient(formData) {
    debugger;
    return apiHelper.post('/' + controllerName + '/update', formData, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}
function getClient(id) {
    debugger;
    return apiHelper.get('/' + controllerName + '/getbyid?id=' + id, authHeader())
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(handleError);
}

export default clientService;