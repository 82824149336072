import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PropaneSharp } from '@mui/icons-material';

// ==============================|| ACCORDION ||============================== //

const ReportAccordion = ({ data, defaultExpandedId = null, expandIcon, square, toggle, defaultExpandId = '', handleSelItemId }) => {
    const theme = useTheme();

    const [itemId, setItemId] = useState(defaultExpandId);
    const [expanded, setExpanded] = useState('acc0');
    // const handleChange = (panel) => (event, newExpanded) => toggle && setExpanded(newExpanded ? panel : false);

    const handleChange = (panel) =>
        (event, newExpanded) => {

            toggle && setExpanded(newExpanded ? panel : false);
            if (itemId === newExpanded.id) {
                setItemId(defaultExpandId);
            }
            else {
                setItemId(panel);
                handleSelItemId(panel);
            }
        }

    useEffect(() => {
        setExpanded(defaultExpandedId);
    }, [defaultExpandedId]);


    return (
        <Box sx={{ width: '100%' }}>
            {data &&
                data.map((item, itemindex) => (
                    <MuiAccordion
                        key={item.id}
                        defaultExpanded={!item.disabled && item.defaultExpand}
                        expanded={(!toggle && !item.disabled && item.expanded) || (toggle && expanded === item.id)}
                        disabled={item.disabled}
                        square={square}
                        onChange={handleChange(item.id)}
                    >
                        <MuiAccordionSummary
                            expandIcon={expandIcon || expandIcon === false ? expandIcon : <ExpandMoreIcon />}
                            sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'white', fontWeight: 500 }}
                        >
                            {item.title}
                        </MuiAccordionSummary>
                        {item.id === itemId &&

                            <MuiAccordionDetails > {item.content}</MuiAccordionDetails>
                        }
                    </MuiAccordion>
                ))
            }
        </Box >
    );
};

ReportAccordion.propTypes = {
    data: PropTypes.array,
    defaultExpandedId: PropTypes.string,
    expandIcon: PropTypes.object,
    square: PropTypes.bool,
    toggle: PropTypes.bool
};

export default ReportAccordion;
