import { useEffect, useState, useRef } from 'react';
import { FormControl, Box, Button, Grid, TextField, Card, form, Typography, Stack, Dialog,DialogContent,DialogContentText } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import EmailEditor from 'react-email-editor';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { CKEditor } from 'ckeditor4-react';
import config from 'config';
import { reportService } from 'services';

export const SendEMail = () => {
	const form = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const [subject, setSubject] = useState("Campain Report Details");
	const [content, setcontent] = useState(null);
	const [toMail, setToMail] = useState();
	const [open, setOpen] = useState(false);
	let cmpid = searchParams.get("cmpid");
	const navigate = useNavigate();
	let userName = 'Name here';
	var urlBase = 'https://reportscape.azurewebsites.net/report/load-dashboard?'
	let dashboardLandingUrl = urlBase + 'cmpid=' + cmpid;
	let url = '<p><a href=' + dashboardLandingUrl + '>Check campain report here</a></p>';
	var templet = '<table border="0" cellpadding="0" cellspacing="0" style="width:650px; background-color:#ffffff; margin:0px auto; padding:0px;">' +
		'<tr style="width:650px; margin:0px; padding:0px;">' +
		'<td align="left" style="width:650px; margin:0px; padding:0px;">' +
		'<img src="https://www.iresearchservices.com/wp-content/uploads/2022/11/irs_header.jpg" style="width:100%;"/></td>' +
		'</tr>' +
		'<tbody>' +
		'<tr style="width:650px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 20px; ">' +
		'<table border="0" cellpadding="0" cellspacing="0" style="width:610px; margin:0px; padding:0px;">' +
		'<tbody><tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px;">' +
		'<table style="width:610px; margin:0px; padding:0px; ">' +
		'<tbody>' +
		'<tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 12px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 14px; color: #444444; margin:0px; padding:0px;">Hello ' + userName + ',</p>' +
		'</td></tr>' +
		'<tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 8px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">' +
		'We hope this email finds you well. We wanted to take a moment to thank you for proividing an opportunity to conduct recent studies for you. We received an overwhelming response and are pleased to share the results with you.</p></td></tr>' +
		'<tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 8px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">Below is the link to our analytics platform "ReportScape", you will find the analytical reports of the survey rapid results. <br/>' + url + '<br/>' +
		'These reports present the findings in graphical format to help you better understand the data. We believe that this information will be beneficial to you as you evaluate our products/services and identify areas for improvement.' +
		'</p></td></tr><tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 8px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">We are proud to share that the majority of our customers are satisfied with our products/services, and we are constantly striving to enhance our offerings to meet your needs better. Your feedback has been invaluable in helping us understand where we can improve, and we are committed to taking the necessary steps to address your concerns.</p>' +
		'</td></tr><tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 8px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">If you have any questions or concerns regarding the survey results or our products/services, please do not hesitate to contact us. We would be more than happy to discuss them with you.' +
		'</p></td></tr>' +
		'<tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 8px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">We appreciate your continued support and look forward to serving you in the future.</p>' +
		'</td></tr>' +
		'<tr style="width:610px; margin:0px; padding:0px;">' +
		'<td style="width:610px; margin:0px; padding:0px 0px 8px 0px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">We&#39;ll be in touch soon.</p>' +
		'</td></tr></tbody></table></td></tr></tbody></table></td></tr>' +
		'<tr style="width:650px; margin:0px; padding:0px;">' +
		'<td align="left" style="width:610px; margin:0px; padding:20px 20px 5px 20px;">' +
		'<p style="float:left; width: 610px; font-family: "verdana"; font-size: 13px; color: #444444; margin:0px; padding:0px;">Warm Regards,</p>' +
		'</td></tr>' +
		'<tr style="width:650px; margin:0px; padding:0px;">' +
		'<td align="left" style="width:610px; margin:0px; padding:0px 20px;">' +
		'<p style="width: 610px; font-family: "verdana"; font-size: 13px; color: #324f91; margin:0px; padding:0px;">iResearch Services</p>' +
		'</td></tr>' +
		'<tr style="width:650px; margin:0px; padding:0px;">' +
		'<td align="left" style="width:650px; margin:0px; padding:0px; height: 40px;">&nbsp;</td>' +
		'</tr><tr style="width:650px; margin:0px; padding:0px;">' +
		'<td align="left" style="width:650px; margin:0px; padding:0px;"><img src="https://www.iresearchservices.com/wp-content/themes/iResearch/img/Email-Footer-2023.png" style="width:100%;" /></td>' +
		'</tr></tbody></table>';

	function sendMailReport(ids, subject, mailbody, attachedFile) {
		reportService.sendMailReport(ids, subject, mailbody, attachedFile)
			.then(response => {
				if (response) {
					setOpen(true);
				}
			})
			.catch(ex => {
				console.log(ex);
				setOpen(false);
			});
	}
	const handleClose = () => {
		setOpen(false);
	  };
	  const handleClickOpen = () => {
		setOpen(true);
	  };

	const sendMailToAddress = () => {
		let ids = [toMail];
		let subject = "Campain report details"
		let mailbody = CKEDITOR.instances.editor1.getData();
		let attachedFile = ''
		sendMailReport(ids, subject, mailbody, attachedFile);
	}

	return (<>
		<MainCard title="Email campain report" md={8}>
			<Card variant="outlined">
				<Box item md={10} spacing={gridSpacing}>
					<Stack mt={2} style={{ left: 20 }}>
						<Typography variant="subtitle1" ><b pt={1} style={{ left: 20 }}>To</b>
							<TextField name="email" style={{ width: 600, left: 20 }} variant="outlined"
								onChange={(e) => {
									setToMail(e.target.value);
								}
								} />

						</Typography>
					</Stack>
				</Box>
				<Grid item container sx={{ mt: 4, me: 3, ps: 1 }} >
					<Box>
						<Typography><b>Email templete (replace name and report link)</b></Typography>
					</Box>
				</Grid>
				<Grid >
					<Typography></Typography>
					<CKEditor config={config.heiht = 700} initData={templet} />
				</Grid>
				<Box mt={4} mb={2} ml={12} spacing={24} >
					<Button ml={6} onClick={sendMailToAddress} variant="contained">Send mail</Button>
					<Button style={{ left: 20 }} variant="outlined" onClick={() => { navigate('/campaigns') }}>Cancel</Button>
				</Box>
			</Card>
		</MainCard>
				<div>
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Email Sent Successfully !
							</DialogContentText>
						</DialogContent>
					</Dialog>
				</div>
		
			</>);
};
		export default SendEMail;