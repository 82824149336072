// material-ui
import { useTheme } from '@mui/material/styles';

import logoDark from 'assets/images/logo-white.svg';
import logo from 'assets/images/logo-dark.svg';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return <img src={theme.palette.mode === 'dark' ? logoDark : logoDark} alt="Report Scape" width="100" />;
};

export default Logo;