




import {
    Button,
    Grid,
    Stack,
    TextField,
    InputLabel,
    CardActionArea
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from 'react';
import { clientService } from 'services/clients/client.service';

const ClientList = (id) => {
    const [clientName, setClientName] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientLogo, setClientLogo] = useState();
    const [clientHeader, setClientHeader] = useState();
    const [clientFooter, setClientFooter] = useState();
    const fileRef = useRef();
    const navigate = useNavigate();
    const [clientResponse, setClientResponse] = useState({
        clientId: '',
        clientName: '',
        clientLogo: '',
        clientHeader: '',
        clientFooter: ''
    })

    useEffect(() => {
        const clientId = searchParams.get("clientId");
        getClient(String(clientId));
    }, []);


    const getClient = function (clientId) {
        debugger;
        clientService.getClient(clientId).then(response => {
            setClientName(response.clientName);
            setClientLogo(response.getClientLogo)
            setClientHeader(response.getClientHeader)
            setClientFooter(response.getClientFooter)
        })
            .catch(err => {
                console.log(err);
                alert("client data is not loaded.")
            });
    }

    const saveLogo = (e) => {
        setClientLogo(e.target.files[0]);
    };
    const saveFooter = (e) => {
        setClientFooter(e.target.files[0]);
    };
    const saveHeader = (e) => {
        setClientHeader(e.target.files[0]);
    };


    const updateClient = (e) => {
        debugger;
        let client = {
            formData: new FormData()
        }
        client.formData.append('clientId', String(searchParams.get("clientId")));
        client.formData.append('clientName', clientName);
        client.formData.append('clientLogo', clientLogo);
        client.formData.append('clientHeader', clientHeader);
        client.formData.append('clientFooter', clientFooter);
        client.formData.append('createdBy', 'Test');
        client.formData.append('getClientLogo', 'Test');
        client.formData.append('getClientHeader', 'Test');
        client.formData.append('getClientFooter', 'Test');
        clientService.updateClient(client.formData)
            .then(response => {
                if (response)
                    alert("client is updated.")
            })
            .catch(err => {
                console.log(err);
                alert("client is not updated.")
            });
    };


    return (<>
        <MainCard title="Edit Client">
            <Grid container>
                <Grid item xs={6} md={5}>
                    <Stack>
                        <InputLabel required>Client Name</InputLabel>
                        <TextField
                            fullWidth
                            id="clientName"
                            name="clientName"
                            placeholder="Client name here"
                            value={clientName}
                        />
                    </Stack>
                    <Grid mt={3}>
                        <CardActionArea >
                            <InputLabel required> Client Logo</InputLabel>
                            <img src={"data:image/png;base64," + clientLogo} />
                            <input id="btn-upload"
                                name="btn-upload"
                                type="file"
                                ref={fileRef}
                                onChange={saveLogo}
                                accept="image/png" />
                        </CardActionArea>
                    </Grid>
                    <Grid mt={3} >
                        <CardActionArea >
                            <InputLabel required> Client Header</InputLabel>
                            <img src={"data:image/png;base64," + clientHeader} />
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                type="file"
                                ref={fileRef}
                                onChange={saveHeader}
                                accept="image/png"
                            />
                        </CardActionArea>
                    </Grid>
                    <Grid mt={3}>
                        <CardActionArea >
                            <InputLabel required> Client Footer</InputLabel>
                            <img src={"data:image/png;base64," + clientFooter} />
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                type="file"
                                ref={fileRef}
                                onChange={saveFooter}
                                accept="image/png"
                            />
                        </CardActionArea>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <AnimateButton>
                            {/* <Button variant="contained" type="submit" onClick={(e) => submitClient(e)}>
                                    Add Client
                                </Button> */}

                            <Button variant="contained" type="button" onClick={(e) => { updateClient(e) }}>
                            Update Client
                            </Button>

                        </AnimateButton>
                        <AnimateButton>
                            <Button style={{}} variant="contained" onClick={() => { navigate('/campaigns') }}>Cancel</Button>
                            {/* <Button variant="contained" type="button">
                                Cancel
                            </Button> */}
                        </AnimateButton>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard >
    </>)

}

export default ClientList;