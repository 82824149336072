import {
    Button,
    Grid,
    Stack,
    TextField,
    InputLabel,
    CardActionArea
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { reportService, campaignService } from 'services';
import { useEffect, useState, useRef } from 'react';
import { clientService } from 'services/clients/client.service';


const validationSchema = yup.object({
    clientName: yup.string().min(5, 'Client name should be of minimum 5 characters').required('Client name is required'),
});

const CreateClient = () => {
    const [fileSelected, setFileSelected] = useState();
    const [headerImage, setHeaderImage] = useState();
    const [footerImage, setFooterImage] = useState();
    const [clientName, setClientName] = useState('');
    const fileRef = useRef();
    const navigate = useNavigate();

    // const formik = useFormik(
    //     {
    //         initialValues: {
    //             Id: '',
    //             clientName: '',
    //             clientLogo: '',
    //             clientHeader: '',
    //             clientFooter: '',
    //             createdBy: '',
    //             createdOn: null
    //         },
    //         validationSchema,
    //         onSubmit: values => {
    //             let client = {
    //                 formData: new FormData()
    //             }
    //             client.formData.append('clientId', null);
    //             client.formData.append('clientName', clientName);
    //             client.formData.append('clientLogo', fileSelected);
    //             client.formData.append('clientHeader', headerImage);
    //             client.formData.append('clientFooter', footerImage);
    //             client.formData.append('createdBy', 'Test');
    //             campaignService.createClient(client.formData)
    //                 .then(response => {
    //                     if (response)
    //                         alert("client is saved.")
    //                 })
    //                 .catch(err => {
    //                     console.log(err);
    //                     alert("client is not saved.")
    //                 });

    //         }
    //     });




    const saveFileSelected = (e) => {
        setFileSelected(e.target.files[0]);
    };
    const saveFooter = (e) => {
        setFooterImage(e.target.files[0]);
    };
    const saveHeader = (e) => {
        setHeaderImage(e.target.files[0]);
    };
    const clientNameChange = (e) => {
        setClientName(e.target.value);
    };
    const submitClient = (e) => {
        let client = {
            formData: new FormData()
        }
        client.formData.append('clientId', null);
        client.formData.append('clientName', clientName);
        client.formData.append('clientLogo', fileSelected);
        client.formData.append('clientHeader', headerImage);
        client.formData.append('clientFooter', footerImage);
        client.formData.append('createdBy', 'Test');
        client.formData.append('getClientLogo', 'Test');
        client.formData.append('getClientHeader', 'Test');
        client.formData.append('getClientFooter', 'Test');
        console.log(client.formData);
        clientService.createClient(client.formData)
            .then(response => {
                if (response)
                    alert("client is saved.")
            })
            .catch(err => {
                console.log(err);
                alert("client is not saved.")
            });
    };

    return (<>
        <MainCard title="Create Client">
                <Grid container>
                    <Grid item xs={6} md={5}>
                        <Stack>
                            <InputLabel required>Client Name</InputLabel>
                            <TextField
                                fullWidth
                                id="clientName"
                                name="clientName"
                                // onChange={clientNameChange}
                                placeholder="Client name here"
                                value={clientName}
                                onChange={clientNameChange}
                                // onBlur={formik.handleBlur}
                                // error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                                // helperText={formik.touched.clientName && formik.errors.clientName}
                            />
                        </Stack>
                        <Grid mt={3}>
                            <CardActionArea >
                                <InputLabel required>Upload Client Logo</InputLabel>
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    type="file"
                                    ref={fileRef}
                                    onChange={saveFileSelected}
                                    accept="image/*"                           
                                />
                            </CardActionArea>
                        </Grid>
                        <Grid mt={3} >
                            <CardActionArea >
                                <InputLabel required>Upload Client Header</InputLabel>
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    type="file"
                                    ref={fileRef}
                                    onChange={saveHeader}
                                    accept="image/png"
                                />
                            </CardActionArea>
                        </Grid>
                        <Grid mt={3}>
                            <CardActionArea >
                                <InputLabel required>Upload Client Footer</InputLabel>
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    type="file"
                                    ref={fileRef}
                                    onChange={saveFooter}
                                    accept="image/png"
                                />
                            </CardActionArea>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                            <AnimateButton>
                                {/* <Button variant="contained" type="submit" onClick={(e) => submitClient(e)}>
                                    Add Client
                                </Button> */}
                                <Button variant="contained" type="button" onClick={(e)=>{submitClient(e)}}>
                                    Add Client
                                </Button>

                            </AnimateButton>
                            <AnimateButton>
                                <Button style={{}} variant="contained" onClick={() => { navigate('/clients/ClientList') }}>Cancel</Button>
                                {/* <Button variant="contained" type="button">
                                Cancel
                            </Button> */}
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
        </MainCard>
    </>)

}

export default CreateClient;