import { useEffect, useMemo, useState } from 'react';
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import ReportSidebar from './Sidebar/reportSidebar';
import HorizontalBar from './HorizontalBar';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

import navigation from 'menu-items';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import { FormattedMessage } from 'react-intl';
import { reportService } from 'services';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
            marginTop: 88
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? '20px' : 0,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginTop: 88
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //
const icons = { EqualizerIcon, AutoGraphIcon };
const ReportLayout = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { drawerType, container, layout } = useConfig();
    const [open, setOpen] = useState(drawerType === LAYOUT_CONST.DEFAULT_DRAWER && drawerOpen);
    const [pages, SetPages] = useState({});


    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            setOpen(true);
            dispatch(openDrawer(true));
        } else {
            setOpen(false);
            dispatch(openDrawer(false));
        }
    }, [drawerType]);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer(!open));
    };

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
        if (searchParams.get("cmpid") != null) {
            reportService.getReportsByCampaignId(searchParams.get("cmpid"))
                .then(resp => {
                    if (resp && resp.length > 0)
                        localStorage.setItem("reportData", JSON.stringify(resp));
                    let childmenu = [];
                    let dashboardLandingUrl = '';
                    const dynamicpagesarr = resp.map((repObj) => {
                        let cmpid = repObj.campaignId;
                        let cmpName=repObj.campaignName;
                        let rid = repObj.id;
                        dashboardLandingUrl = dashboardLandingUrl == '' ? '/report/load-dashboard?cmpName='+cmpName+'&cmpid=' + cmpid + '&reportId=' + rid : dashboardLandingUrl;
                        childmenu.push({
                            id: repObj.menuTitle,
                            title: <FormattedMessage id={repObj.menuTitle} />,
                            type: 'item',
                            url: '/report/report-container?cmpid=' + cmpid + '&reportId=' + rid,
                            breadcrumbs: false,
                            icon: icons.AutoGraphIcon
                        })
                    });

                    const pagesarr = {
                        id: 'pages',
                        title: <FormattedMessage id="Client Dashboard" />,
                        caption: <FormattedMessage id="pages-caption" />,
                        type: 'group',
                        children: [
                            {
                                id: 'master menu',
                                title: <FormattedMessage id="Report Menu" />,
                                type: 'collapse',
                                children: childmenu,
                                icon: icons.EqualizerIcon
                            }
                        ]
                    };

                    SetPages(pagesarr);

                    console.log("after setting values:", pages);
                    // navigate(childmenu[0].url);
                    navigate(dashboardLandingUrl);
                })
                .catch(e => console.log(e));
        }
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            setOpen(true);
            dispatch(openDrawer(true));
        }
    }, [matchDownMd]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? '10px' : '16px' }}>
                <Header open={open} handleDrawerToggle={handleDrawerToggle} admin={false}/>
            </Toolbar>
        ),
        [layout, matchDownMd, open]
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: '#002658',
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
            </AppBar>

            {/* horizontal menu-list bar */}
            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

            {/* drawer */}
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (pages != null && pages != undefined && Object.keys(pages).length > 0 && <ReportSidebar pages={pages} open={open} handleDrawerToggle={handleDrawerToggle} />)}

            {/* main content */}
            <Main theme={theme} open={drawerOpen} layout={layout}>
                <Container maxWidth={container ? 'lg' : false}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
};

export default ReportLayout;
