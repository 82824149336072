import PropTypes from 'prop-types';

// material-ui
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,Tooltip } from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useTheme } from '@emotion/react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
// ==============================|| Campaign Item-DATA PAGE ||============================== //

function CampaignItemPage({ campaignReports, deleteReportHandler }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const cmpName = searchParams.get("cmpName");
    const theme = useTheme();
    console.log("campaignReports", campaignReports);
    return (
        <>
            {campaignReports.length ? (
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 3 }}>Report Title</TableCell>
                                    <TableCell sx={{ pl: 3 }}>Menu Title</TableCell>
                                    <TableCell sx={{ pl: 3 }}>Report Type</TableCell>
                                    <TableCell align="center" sx={{ pr: 3 }}>
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                                            Action
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {campaignReports.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ pl: 3 }}>
                                            <Typography align="left" variant="subtitle1">
                                                {row.reportTitle}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ pl: 3 }}>{row.menuTitle}</TableCell>
                                        <TableCell sx={{ pl: 3 }}>{row.chartType}</TableCell>

                                        <TableCell align="center" sx={{ pr: 3 }}>
                                        <Tooltip title="Edit report">
                                            <IconButton color="primary" size="large">
                                                <Link target="_blank" to={`/campaigns/CampaignReportContainer?cmpid=${row.campaignId}&reportId=${row.id}&cmpName=${cmpName}`} state={{ some: "value" }}>
                                                    <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                </Link>
                                            </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete report">
                                            <IconButton color="error" size="small" onClick={() => deleteReportHandler(row.id)}>
                                                <Link to={`/campaigns/Edit-Campaign?cmpid=${row.id}`}>
                                                    <DeleteTwoToneIcon fontSize="small" />
                                                </Link>
                                            </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ) : null}
        </>
    );
}

CampaignItemPage.propTypes = {
    campaignReports: PropTypes.array,
    deleteReportHandler: PropTypes.func
};

export default CampaignItemPage;
