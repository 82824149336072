// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const masters = {
    id: 'sample-docs-roadmap',
    title: <FormattedMessage id="Masters" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'Country',
            title: 'Country',
            type: 'item',
            url: '/masters/country',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
    ]
};

export default masters;
