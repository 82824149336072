// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconKey, IconBug, IconAddressBook, IconList, IconSquarePlus, IconBrandCampaignmonitor } from '@tabler/icons';

// constant
const icons = { IconBug, IconKey, IconAddressBook, IconList, IconSquarePlus, IconBrandCampaignmonitor };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: <FormattedMessage id="Report Scape (Admin)" />,
    caption: <FormattedMessage id="pages-caption" />,
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'campaigns',
            title: <FormattedMessage id="campaigns" />,
            type: 'collapse',
            icon: icons.IconBrandCampaignmonitor,
            children: [
                {
                    id: 'List',
                    title: <FormattedMessage id="Campaign-List" />,
                    type: 'item',
                    url: '/campaigns',
                    breadcrumbs: false,
                    icon: icons.IconList
                }, {
                    id: 'Create',
                    title: <FormattedMessage id="create-campaign" />,
                    type: 'item',
                    url: '/campaigns/create-campaign',
                    breadcrumbs: false,
                    icon: icons.IconSquarePlus
                }
            ]
        },
        {
            id: 'clients',
            title: <FormattedMessage id="Clients" />,
            type: 'collapse',
            icon: icons.IconBrandCampaignmonitor,
            children: [
                {
                    id: 'ClientList',
                    title: <FormattedMessage id="List" />,
                    type: 'item',
                    url: '/clients/ClientList',
                    breadcrumbs: false,
                    icon: icons.IconSquarePlus
                },
                {
                    id: 'Client',
                    title: <FormattedMessage id="CreateClient" />,
                    type: 'item',
                    url: '/campaigns/CreateClient',
                    breadcrumbs: false,
                    icon: icons.IconSquarePlus
                }
            ]
        }
    ]
};

export default pages;
