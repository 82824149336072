import {
    Button,
    Divider,
    Grid,
    Box,
    InputLabel,
    ListItemText,
    Select,
    MenuItem,
    Typography,
    ListItemButton
} from "@mui/material";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import * as WebDataRocksReact from 'react-webdatarocks';
import ColorTabs from 'ui-component/basic/UITabs/ColorTabs';
import { useTheme } from '@mui/material/styles';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import RadarIcon from '@mui/icons-material/Radar';
import countryMapping from "./countryChatData";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import RTLLayout from "ui-component/RTLLayout";


const icons = { EqualizerIcon, AutoGraphIcon, ShowChartIcon, RadarIcon };


const DynamicChart = ({ questionItem, questionData, emelentId, reportType, renderingDone, questionId, showDataTable, handleChildChartTypes }) => {
    var questionId = String(questionId);
    const theme = useTheme();


    const [showTable, setShowTable] = useState(true);
    const [graphType, setGraphType] = useState((questionItem?.graphType == undefined) ? 'bar' : questionItem?.graphType);
    const [selectedChartType, setChartType] = useState('bar');
    let graphTypeToSave = useRef('bar');
    const chartid = 'dynamicChartContainer' + emelentId;
    const gridId = 'dynamicGridContainer' + emelentId;
    var slice = undefined;
    var hybridChartData = [];
    var mapChartData = [];

    function getHybridData() {
        console.log(questionData)
        for (let item = 0; item < questionData.length; item++) {
            var insideObject = {}
            var obj = {};
            obj.category = questionData[item].Category;
            for (const key of Object.keys(questionData[item])) {
                insideObject.breakdown = [];
                if (key != 'Category') {
                    insideObject.Category = key,
                        insideObject.value = questionData[item][key]
                }
                obj.value = questionData[item][key];
            }
            insideObject.breakdown.push(obj);
            hybridChartData.push(insideObject);
        }
        console.log(hybridChartData)
    }

    useEffect(() => {
        setChartType((questionItem?.graphType == undefined) ? 'bar' : questionItem?.graphType);
        prepareChart((questionItem?.graphType == undefined) ? 'bar' : questionItem?.graphType);
        renderingDone(emelentId);
    }, []);
    useEffect(() => {
        prepareChart((questionItem?.graphType == undefined) ? 'bar' : questionItem?.graphType);
        setChartType((questionItem?.graphType == undefined) ? 'bar' : questionItem?.graphType);
    }, [showDataTable, emelentId]);


    function prepareChart(type) {
        slice = (questionItem?.slice == undefined) ? getSlicedData() : questionItem?.slice;
        document.getElementById(chartid).innerHTML = "";
        var datatable = document.getElementById(gridId);
        if (datatable != undefined && datatable != null)
            datatable.innerHTML = "";

        var rederedChart;
        switch (type) {
            case "bar":
                rederedChart = loadBarChart();
                break;
            case "column":
                rederedChart = loadColumnChart();
                break;
            case "columnwithrotate":
                rederedChart = loadColumnWithRotate();
                break;
            case "pictorial":
                rederedChart = loadPictorialChart();
                break;
            case "variableradious":
                rederedChart = loadVaribleRadiusPieChart();
                break;
            case "line":
                rederedChart = loadLineChart();
                break;
            case "bubble":
                rederedChart = loadBubbleChart();
                break;
            case "pie":
                rederedChart = loadPieChart();
                break;
            case "semicircle":
                rederedChart = loadsemiCirclePieChart();
                break;
            case "riskheatmap":
                rederedChart = loadRiskHeatMapChart();
                break;
            case "columnandline":
                rederedChart = loadColumnAndLineMixChart();
                break;
            case "hybrid":
                rederedChart = loadHybridChart();
                break;
            case "map":
                rederedChart = loadMapwithBabbleChart();
                break;
            default:
                break;
        }
        toShowData();
        //Code to display data in table view
        function toShowData() {
            if (rederedChart != null) {
                if (showDataTable) {
                    rederedChart.events.on("datavalidated", function (ev) {
                        rederedChart.exporting.getHTML("html", {
                            addColumnNames: true,
                            tableClass: "fl-table" // table-striped table-hover table-sm
                        }, false).then(function (html) {
                            var dvtable = document.getElementById(gridId);
                            if (dvtable != null) {
                                dvtable.innerHTML = html;
                            }
                        });

                    });
                }

            }
        }

    }
    function loadBarChart() {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create(chartid, am4charts.XYChart);
        chart.numberFormatter.numberFormat = "###.##";
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in       
        chart.colors.saturation = 0.4;
        chart.data = questionData;

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = getCategoryName(slice);
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.minWidth = 120;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        let label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 140;
        label.truncate = true;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.maxLabelPosition = 0.98;
        for (let s = 0; s < getNumberOfMeasures(slice); s++) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryY = getCategoryName(slice);
            series.dataFields.valueX = getMeasureNameByIndex(slice, s);
            series.name = getMeasureNameByIndex(slice, s);
            series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
            // series.tooltipText = "{valueX.value}";
            series.columns.template.height = am4core.percent(100);
            series.sequencedInterpolation = true;
            series.defaultState.transitionDuration = 1000;
            series.sequencedInterpolationDelay = 100;
            series.columns.template.strokeOpacity = 0;
            series.columns.template.height = am4core.percent(100);
            series.sequencedInterpolation = true;
            series.columns.template.column.cornerRadiusBottomRight = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.fillOpacity = 0.8;
            series.calculatePercent = true;


            var bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueX}({valueX.percent}%)";
            // valueLabel.label.text = "{valueX.percent}%";         
            valueLabel.label.horizontalCenter = "left";
            valueLabel.label.dx = 10;
            valueLabel.label.hideOversized = false;
            valueLabel.label.truncate = false;

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "zoomY";


            // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
            series.columns.template.adapter.add("fill", (fill, target) => {
                return chart.colors.getIndex(target.dataItem.index);
            });
        }
        //Setting legends here with truncate widht
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.maxWidth = 150;
        chart.legend.labels.template.truncate = true;

        //settting scroll on graph
        var scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY = scrollbarY;

        //Set chart height
        chart.svgContainer.htmlElement.style.minHeight = "350px";
        // Set cell size in pixels
        let cellSize = 50;
        chart.events.on("datavalidated", function (ev) {

            // Get objects of interest
            let chart = ev.target;
            let categoryAxis = chart.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            let targetHeight = chart.pixelHeight + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "px";
        });

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadColumnChart() {
        /* Apply amCharts theme */
        am4core.useTheme(am4themes_animated);
        /* Create chart instance */
        var chart = am4core.create(chartid, am4charts.XYChart);
        chart.numberFormatter.numberFormat = "###.##";
        chart.hiddenState.properties.opacity = 0;

        /* Add data processed by WebDataRocks to the chart */
        chart.data = questionData;
        /* Create axes */

        /* Category axis */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = getCategoryName(slice);
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        //Set the category label width
        let label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 140;
        label.truncate = true;
        /* Value axis */
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.numberFormatter = new am4core.NumberFormatter();
        /* Get number formatting from WebDataRocks prepared to a format required by amCharts */
        // var numberFormat = pivot.amcharts.getNumberFormatPattern(rawData.meta.formats[0]);
        // /* Apply number formatting to the chart */
        // valueAxis.numberFormatter.numberFormat = numberFormat;

        valueAxis.min = 0;
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.renderer.baseGrid.disabled = true;
        /* Create and configure series */
        for (let s = 0; s < getNumberOfMeasures(slice); s++) {
            // Create series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = getMeasureNameByIndex(slice, s);
            series.dataFields.categoryX = getCategoryName(slice);
            series.clustered = true;
            series.name = getMeasureNameByIndex(slice, s);
            series.calculatePercent = true;
            series.tooltipText = "{name}: [bold]{valueY}[/]";
            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.fillOpacity = 1.5;
            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueY}({valueY.percent}%)";
            //color the bar
            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
        }

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;


        //Set chart height
        chart.svgContainer.htmlElement.style.minHeight = "350px";
        // Set cell size in pixels
        let cellSize = 50;
        chart.events.on("datavalidated", function (ev) {

            // Get objects of interest
            let chart = ev.target;
            let categoryAxis = chart.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            let targetHeight = chart.pixelHeight + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "px";
        });
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }

    function loadColumnWithRotate() {
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create(chartid, am4charts.XYChart);
        chart.scrollbarX = new am4core.Scrollbar();

        // Add data
        chart.data = questionData;
        chart.numberFormatter.numberFormat = "###.##";
        // let slice = (questionItem?.slice == undefined) ? getSlicedData() : questionItem?.slice;

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = getCategoryName(slice);
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.minHeight = 110;

        /* Value axis */
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;

        //Set the category label width
        let label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 140;
        label.truncate = true;
        /* Create and configure series */
        for (let s = 0; s < getNumberOfMeasures(slice); s++) {
            // Create series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = getMeasureNameByIndex(slice, s);
            series.dataFields.categoryX = getCategoryName(slice);
            series.clustered = false;
            series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
            series.columns.template.strokeWidth = 0;
            series.tooltip.pointerOrientation = "vertical";
            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.fillOpacity = 0.8;
            series.calculatePercent = true;
            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueY}({valueY.percent}%)";

            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.lineX.disabled = true;
            chart.cursor.lineY.disabled = true;

            // Set cell size in pixels
            let cellSize = 30;
            chart.events.on("datavalidated", function (ev) {

                // Get objects of interest
                let chart = ev.target;
                let categoryAxis = chart.yAxes.getIndex(0);

                // Calculate how we need to adjust chart height
                let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

                // get current chart height
                let targetHeight = chart.pixelHeight + adjustHeight;

                // Set it on chart's container
                chart.svgContainer.htmlElement.style.minHeight = targetHeight + "px";
            });
        }

        //Set chart height
        chart.svgContainer.htmlElement.style.height = "550px";
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadPictorialChart() {


        am4core.useTheme(am4themes_animated);
        // Themes end

        var iconPath = "M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z"

        var chart = am4core.create(chartid, am4charts.SlicedChart);
        chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

        chart.data = questionData;

        chart.numberFormatter.numberFormat = "###.##";
        var series = chart.series.push(new am4charts.PictorialStackedSeries());
        series.dataFields.value = getMeasureNameByIndex(slice, 0);
        series.dataFields.category = getCategoryName(slice);
        series.alignLabels = true;

        series.maskSprite.path = iconPath;
        series.ticks.template.locationX = 1;
        series.ticks.template.locationY = 0.5;

        series.labelsContainer.width = 200;


        chart.legend = new am4charts.Legend();
        chart.legend.position = "left";
        chart.legend.valign = "bottom";

        //Set chart height
        chart.svgContainer.htmlElement.style.minHeight = "350px";
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    };
    function loadVaribleRadiusPieChart() {
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create(chartid, am4charts.PieChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = questionData;
        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = getMeasureNameByIndex(slice, 0);
        series.dataFields.radiusValue = getMeasureNameByIndex(slice, 0);
        series.dataFields.category = getCategoryName(slice);
        series.colors.step = 3;
        series.ticks.template.disabled = true;
        series.labels.template.disabled = true;
        series.calculatePercent = true;
        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{slice}({slice.percent}%)";

        series.hiddenState.properties.endAngle = -90;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.svgContainer.htmlElement.style.minHeight = "350px";
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadLineChart() {
        /* Apply amCharts theme */
        am4core.useTheme(am4themes_animated);

        /* Create chart instance */
        var chart = am4core.create(chartid, am4charts.XYChart);
        chart.paddingRight = 10;
        /* Add data processed by WebDataRocks to the chart */
        chart.data = questionData;

        chart.numberFormatter.numberFormat = "###.##";
        var categoryAxis1 = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis1.dataFields.category = getCategoryName(slice);
        categoryAxis1.renderer.grid.template.location = 0;
        // categoryAxis1.renderer.labels.template.rotation = 270;
        // categoryAxis1.renderer.minHeight = 110;
        let label = categoryAxis1.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 120;
        label.truncate = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;
        valueAxis.renderer.axisFills.template.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;

        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.categoryX = getCategoryName(slice);
        series.dataFields.valueY = getMeasureNameByIndex(slice, 0);
        series.strokeWidth = 3;
        series.tensionX = 0.8;
        series.bullets.push(new am4charts.CircleBullet());
        series.tooltip.text = "{categoryX}: {valueY}";
        series.propertyFields.stroke = "color";
        series.xAxes = categoryAxis1;
        series.calculatePercent = true;
        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{categoryX}({valueY.percent}%)";
        // Enable interactions on series segments
        let segment = series.segments.template;
        segment.interactionsEnabled = true;

        // Create hover state
        var hs = segment.states.create("hover");
        hs.properties.strokeWidth = 10;
        chart.legend = new am4charts.Legend();

        chart.svgContainer.htmlElement.style.minHeight = "350px";
        var yAxes = chart.yAxes.getIndex(0);

        // Set cell size in pixels
        let cellSize = 30;
        chart.events.on("datavalidated", function (ev) {

            // Get objects of interest
            let chart = ev.target;
            let categoryAxis = chart.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            let targetHeight = chart.pixelHeight + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "px";
        });
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadBubbleChart() {
        am4core.useTheme(am4themes_animated);
        /* Create chart instance */
        var chart = am4core.create(chartid, am4charts.XYChart);
        /* Add data processed by WebDataRocks to the chart */
        chart.data = questionData;
        chart.numberFormatter.numberFormat = "###.##";
        var valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxisX.renderer.ticks.template.disabled = true;
        valueAxisX.renderer.axisFills.template.disabled = true;

        var valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisY.renderer.ticks.template.disabled = true;
        valueAxisY.renderer.axisFills.template.disabled = true;
        for (let s = 0; s < getNumberOfMeasures(slice); s++) {
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueX = getMeasureNameByIndex(slice, s);
            series.dataFields.valueY = getMeasureNameByIndex(slice, s);
            series.dataFields.value = getMeasureNameByIndex(slice, s);

            let measure1 = getMeasureNameByIndex(slice, s);

            series.calculatePercent = true;
            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueX}({valueX.percent}%)";

            series.strokeOpacity = 0;
            series.sequencedInterpolation = true;
            series.tooltip.pointerOrientation = "Value";

            let bullet = series.bullets.push(new am4core.Circle());
            bullet.fill = am4core.color("#ff0000");
            bullet.propertyFields.fill = "color";
            bullet.strokeOpacity = 0;
            bullet.strokeWidth = 2;
            bullet.fillOpacity = 0.5;
            bullet.stroke = am4core.color("#ffffff");
            bullet.hiddenState.properties.opacity = 0;
            bullet.tooltipText = `[bold]{Name}:[/]\n ${measure1}: {valueX.value}`;


            bullet.adapter.add("tooltipY", function (tooltipY, target) {
                return -target.radius;
            });

            bullet.events.on("over", function (event) {
                let target = event.target;
                outline.radius = target.pixelRadius + 2;
                outline.x = target.pixelX;
                outline.y = target.pixelY;
                outline.show();
            });

            bullet.events.on("out", function (event) {
                outline.hide();
            });
            series.heatRules.push({ target: bullet, min: 2, max: 60, property: "radius" });

            let hoverState = bullet.states.create("hover");
            hoverState.properties.fillOpacity = 1;
            hoverState.properties.strokeOpacity = 1;
            let outline = chart.plotContainer.createChild(am4core.Circle);
            outline.fillOpacity = 0;
            outline.strokeOpacity = 0.8;
            outline.stroke = am4core.color("#ff0000");
            outline.strokeWidth = 2;
            outline.hide(0);

            let blurFilter = new am4core.BlurFilter();
            outline.filters.push(blurFilter);


            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "zoomXY";
            chart.cursor.snapToSeries = series;
        }
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();
        chart.svgContainer.htmlElement.style.minHeight = "350px";

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadPieChart() {
        /* Apply amCharts theme */
        am4core.useTheme(am4themes_animated);

        /* Create chart instance */
        var chart = am4core.create(chartid, am4charts.PieChart);

        /* Add data processed by WebDataRocks to the chart */
        chart.data = questionData;
        // chart.numberFormatter.numberFormat = "###.##";

        /* Create and configure series for a pie chart */
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.category = getCategoryName(slice);
        pieSeries.dataFields.value = getMeasureNameByIndex(slice, 0);
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.calculatePercent = true;
        var valueLabel = pieSeries.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueX}({valueX.percent}%)";

        pieSeries.slices.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });
        /* Create initial animation */
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.svgContainer.htmlElement.style.minHeight = "350px";

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadsemiCirclePieChart() {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create(chartid, am4charts.PieChart);
        chart.hiddenState.properties.opacity = 0;
        chart.data = questionData;
        chart.numberFormatter.numberFormat = "###.##";
        /* Set set an angle for a pie chart to start on and an angle to end on */
        chart.radius = am4core.percent(70);
        chart.innerRadius = am4core.percent(40);
        chart.startAngle = 180;
        chart.endAngle = 360;


        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.category = getCategoryName(slice);
        series.dataFields.value = getMeasureNameByIndex(slice, 0);

        series.slices.template.cornerRadius = 10;
        series.slices.template.innerCornerRadius = 7;
        series.slices.template.draggable = true;
        series.slices.template.inert = true;
        series.alignLabels = false;

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;

        chart.legend = new am4charts.Legend();
        chart.svgContainer.htmlElement.style.minHeight = "350px";
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadRiskHeatMapChart() {

        // Apply chart themes
        am4core.useTheme(am4themes_animated);
        debugger;
        var chart = am4core.create(chartid, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        chart.data = questionData;
        chart.maskBullets = false;
        chart.numberFormatter.numberFormat = "###.##";
        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = getCategoryName(slice);
        yAxis.dataFields.category = getCategoryName(slice);

        let xaxislabel = xAxis.renderer.labels.template;
        xaxislabel.wrap = true;
        xaxislabel.maxWidth = 140;
        xaxislabel.truncate = true;

        let yaxislabel = yAxis.renderer.labels.template;
        yaxislabel.wrap = true;
        yaxislabel.maxWidth = 140;
        yaxislabel.truncate = true;

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = getCategoryName(slice);
        series.dataFields.categoryY = getCategoryName(slice);
        series.dataFields.value = getMeasureNameByIndex(slice, 0);
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;

        series.calculatePercent = true;
        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{categoryX}({categoryX.percent}%)";

        // Set up column appearance
        var column = series.columns.template;
        column.strokeWidth = 2;
        column.strokeOpacity = 1;
        column.stroke = am4core.color("#ffffff");
        column.tooltipText = "{categoryX}, {categoryY}: {value.workingValue.formatNumber('#.')}";
        column.width = am4core.percent(100);
        column.height = am4core.percent(100);
        column.column.cornerRadius(6, 6, 6, 6);
        column.propertyFields.fill = "color";

        // Set up bullet appearance
        var bullet1 = series.bullets.push(new am4charts.CircleBullet());
        bullet1.circle.propertyFields.radius = "value";
        bullet1.circle.fill = am4core.color("#000");
        bullet1.circle.strokeWidth = 0;
        bullet1.circle.fillOpacity = 0.7;
        bullet1.interactionsEnabled = false;

        var bullet2 = series.bullets.push(new am4charts.LabelBullet());
        bullet2.label.text = "{value}";
        bullet2.label.fill = am4core.color("#fff");
        bullet2.zIndex = 1;
        bullet2.fontSize = 11;
        bullet2.interactionsEnabled = false;

        chart.svgContainer.htmlElement.style.minHeight = "350px";
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadColumnAndLineMixChart() {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create(chartid, am4charts.XYChart);
        chart.numberFormatter.numberFormat = "###.##";
        // Export
        chart.exporting.menu = new am4core.ExportMenu();

        // Data for both series
        var data = [{
            "year": "2009",
            "income": 23.5,
            "expenses": 21.1
        }, {
            "year": "2010",
            "income": 26.2,
            "expenses": 30.5
        }, {
            "year": "2011",
            "income": 30.1,
            "expenses": 34.9
        }, {
            "year": "2012",
            "income": 29.5,
            "expenses": 31.1
        }, {
            "year": "2013",
            "income": 30.6,
            "expenses": 28.2,
            "lineDash": "5,5",
        }, {
            "year": "2014",
            "income": 34.1,
            "expenses": 32.9,
            "strokeWidth": 1,
            "columnDash": "5,5",
            "fillOpacity": 0.2,
            "additional": "(projection)"
        }];
        /* Create axes */
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.minGridDistance = 30;

        /* Create value axis */
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        /* Create series */
        var columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = "Income";
        columnSeries.dataFields.valueY = "income";
        columnSeries.dataFields.categoryX = "year";

        columnSeries.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
        columnSeries.columns.template.propertyFields.stroke = "stroke";
        columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
        columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
        columnSeries.tooltip.label.textAlign = "middle";

        columnSeries.calculatePercent = true;
        var valueLabel = columnSeries.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{categoryX}({categoryX.percent}%)";

        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = "Expenses";
        lineSeries.dataFields.valueY = "expenses";
        lineSeries.dataFields.categoryX = "year";
        lineSeries.calculatePercent = true;
        var valueLabel = lineSeries.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{categoryX}({categoryX.percent}%)";

        lineSeries.stroke = am4core.color("#fdd400");
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.strokeDasharray = "lineDash";
        lineSeries.tooltip.label.textAlign = "middle";

        var bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
        bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        var circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 3;

        chart.data = data;
        chart.svgContainer.htmlElement.style.minHeight = "350px";

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function loadHybridChart() {
        getHybridData();
        am4core.ready(function () {

            am4core.useTheme(am4themes_animated);

            // Create chart instance
            var chart = am4core.create(chartid, am4core.Container);
            chart.width = am4core.percent(100);
            chart.height = am4core.percent(100);
            chart.layout = "horizontal";
            chart.numberFormatter.numberFormat = "###.##";
            // Create chart instance
            var columnChart = chart.createChild(am4charts.XYChart);

            // Create axes
            var categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.inversed = true;

            var valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

            // Create series
            var columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
            columnSeries.dataFields.valueX = "value";
            columnSeries.dataFields.categoryY = "category";
            columnSeries.columns.template.strokeWidth = 0;
            columnSeries.calculatePercent = true;
            var valueLabel = columnSeries.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{category}({category.percent}%)";

            // Create chart instance
            var pieChart = chart.createChild(am4charts.PieChart);
            pieChart.data = hybridChartData;
            pieChart.innerRadius = am4core.percent(50);

            // Add pieChart to validated sprites
            chart.exporting.validateSprites.push(pieChart);
            // Enable pieChart on export
            chart.exporting.events.on("exportstarted", function (ev) {
                pieChart.disabled = false;
            });

            // Add and configure Series
            var pieSeries = pieChart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "Category";
            pieSeries.slices.template.propertyFields.fill = "color";
            pieSeries.labels.template.disabled = true;

            pieSeries.calculatePercent = true;
            var valueLabel = pieSeries.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{category}({category.percent}%)";
            // Set up labels
            var label1 = pieChart.seriesContainer.createChild(am4core.Label);
            label1.text = "";
            label1.horizontalCenter = "middle";
            label1.fontSize = 35;
            label1.fontWeight = 600;
            label1.dy = -30;

            var label2 = pieChart.seriesContainer.createChild(am4core.Label);
            label2.text = "";
            label2.horizontalCenter = "middle";
            label2.fontSize = 12;
            label2.dy = 20;

            // Auto-select first slice on load
            pieChart.events.on("ready", function (ev) {
                pieSeries.slices.getIndex(0).isActive = true;
            });

            // Set up toggling events
            pieSeries.slices.template.events.on("toggled", function (ev) {
                if (ev.target.isActive) {

                    // Untoggle other slices
                    pieSeries.slices.each(function (slice) {
                        if (slice != ev.target) {
                            slice.isActive = false;
                        }
                    });

                    // Update column chart
                    columnSeries.appeared = false;
                    columnChart.data = ev.target.dataItem.dataContext.breakdown;
                    columnSeries.fill = ev.target.fill;
                    columnSeries.reinit();
                    var valueLabel = columnSeries.bullets.push(new am4charts.LabelBullet());
                    valueLabel.label.text = "{valueX}";
                    valueLabel.label.horizontalCenter = "left";
                    valueLabel.label.dx = 10;
                    valueLabel.label.hideOversized = false;
                    valueLabel.label.truncate = false;
                    // Update labels
                    label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
                    label1.fill = ev.target.fill;

                    label2.text = ev.target.dataItem.category;
                }
            });
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.align = "left";
            chart.exporting.menu.verticalAlign = "top";
            return chart;
        });

    }
    function loadMapwithBabbleChart() {
        if (questionItem.data != null) {
            questionItem.data.forEach((el, i) => {
                countryMapping.forEach((element, index) => {
                    if (element.name === el.Name) {
                        var matchRecord = {
                            "id": element.id,
                            "name": el.Name,
                            "value": el.Value
                        }
                        mapChartData.push(matchRecord);
                    }
                });
            });
        }
        debugger;
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end
        graphTypeToSave.current = 'map';
        // Create map instance
        var chart = am4core.create(chartid, am4maps.MapChart);
        chart.numberFormatter.numberFormat = "###.##";

        var mapData = mapChartData;
        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        polygonSeries.useGeodata = true;
        polygonSeries.nonScalingStroke = true;
        polygonSeries.strokeWidth = 0.5;
        polygonSeries.calculateVisualCenter = true;
        polygonSeries.calculatePercent = true;
        var valueLabel = polygonSeries.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{value}({value.percent}%)";


        var imageSeries = chart.series.push(new am4maps.MapImageSeries());
        imageSeries.data = mapData;
        imageSeries.dataFields.value = "value";

        var imageTemplate = imageSeries.mapImages.template;
        imageTemplate.nonScaling = true

        var circle = imageTemplate.createChild(am4core.Circle);
        circle.fillOpacity = 0.7;
        circle.propertyFields.fill = "color";
        circle.fill = am4core.color("#ff0000");
        circle.stroke = am4core.color("#ffffff");
        circle.strokeOpacity = 0;
        circle.strokeWidth = 2;
        circle.hiddenState.properties.opacity = 0;
        circle.tooltipText = "{name}: [bold]{value}[/]";
        circle.exportable = true;

        imageSeries.heatRules.push({
            "target": circle,
            "property": "radius",
            "min": 4,
            "max": 30,
            "dataField": "value"
        })

        imageTemplate.adapter.add("latitude", function (latitude, target) {
            var polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
            if (polygon) {
                return polygon.visualLatitude;
            }
            return latitude;
        })

        imageTemplate.adapter.add("longitude", function (longitude, target) {
            var polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
            if (polygon) {
                return polygon.visualLongitude;
            }
            return longitude;
        })
        chart.svgContainer.htmlElement.style.minHeight = "350px";

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        return chart;
    }
    function getSlicedData() {
        let slice = { rows: [], columns: [], measures: [] };
        if (questionItem?.slice == undefined) {
            return (reportType == 'CrossTab') ? getSliceForCrossTabReport(slice, questionItem) : getSliceForOverAllReport(slice, questionItem);
        }
        else
            return questionItem?.slice;
    }
    function getSliceForOverAllReport(slice, questionItem) {
        slice.rows.push({ "uniqueName": "Name" });
        slice.columns.push({ "uniqueName": "Measures" });
        slice.measures.push({ "uniqueName": "Value", "aggregation": "sum" });
        return slice;
    }
    function getSliceForCrossTabReport(slice, questionItem) {
        slice.rows.push({ "uniqueName": "Category" });
        slice.columns.push({ "uniqueName": "Measures" });
        if (questionItem.data.length > 0)
            slice.measures.push({ "uniqueName": Object.keys(questionItem.data[0])[1], "aggregation": "sum" });
        return slice;
    }
    function getCategoryName(slice) {
        return slice.rows[0].uniqueName;
    }
    function getNumberOfMeasures(slice) {
        return slice.measures.length;
    }
    function getMeasureNameByIndex(slice, index) {
        return slice.measures[index].uniqueName;
    }
    const handleChange = (e) => {
        prepareChart(e.target.value);
        setChartType(e.target.value);
        handleChildChartTypes(e);
    };

    return ((questionData) && <>
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="h4">{questionItem?.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2">{questionItem?.title}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedChartType}
                                onChange={(e) => handleChange(e)}
                                style={{ height: 30 }}
                                name={emelentId}
                            >
                                <MenuItem value={"bar"}>Bar</MenuItem>
                                <MenuItem value={"column"}>Column</MenuItem>
                                <MenuItem value={"columnwithrotate"}>Column with roatate</MenuItem>
                                <MenuItem value={"pictorial"}>Pictorial</MenuItem>
                                <MenuItem value={"variableradious"}>Varible Radius Pie Chart</MenuItem>
                                <MenuItem value={"line"}>Line Chart</MenuItem>
                                <MenuItem value={"bubble"}>Bubble Chart</MenuItem>
                                <MenuItem value={"pie"}>Pie Chart</MenuItem>
                                <MenuItem value={"semicircle"}>Semi Circle Pie Chart</MenuItem>
                                <MenuItem value={"riskheatmap"}>Risk Heat Map Chart</MenuItem>
                                {/* <MenuItem value={"columnandline"}>Column And Heat Map Chart</MenuItem> */}
                                <MenuItem value={"hybrid"}>Hybrid Chart</MenuItem>
                                <MenuItem value={"map"}>Map With Bubble Chart</MenuItem>

                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} sx={{ '': { marginLeft: RTLLayout ? '8px' : 'initial' } }} style={{ minWidth: '500px' }}>
                    <div id={'dynamicChartContainer' + `${emelentId}`} className={showTable == true ? 'show-div' : 'show-div'}></div>
                    <div style={{ maxHeight: '400px', overflow: 'auto', width: '100%' }} id={'dynamicGridContainer' + `${emelentId}`} className={showTable == true ? 'show-div' : 'hide-div'}></div>

                </Grid>
            </Grid>
        </MainCard>

        {/* <style>{"table{border: 1px solid black !important; border-spacing: 0px !important;}, tr td {border: 1px solid black !important; border-spacing: 0px !important;}"}</style> */}

    </>);


}
export default DynamicChart;