import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { clientService } from 'services/clients/client.service';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Paper from '@mui/material/Paper';
import {
    Box,
    CardContent,
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Select,
    MenuItem,
    Button,
    Typography
} from '@mui/material';

import TableFooter from '@mui/material/TableFooter';
import { visuallyHidden } from '@mui/utils';



const ClientList = () => {
    const [clientList, setClientList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [clientCount,setCount]=React.useState(0);
    const [paging, setPaging] = useState({
        pageNo: 0,
        pageSize: 5
    });

    React.useEffect(() => {
        clientService.getClientsCount()
            .then(c => setCount(c)
            )
            .catch(e => console.log(e));
    }, []);

    useEffect(() => {
        clientListData(paging);
    }, [paging]);
    const columns = [
        { field: 'clientName', headerName: 'Client Name', width: 300 },
        { field: 'createdBy', headerName: 'Created By', width: 300 },
        { field: 'Action', headerName: 'Action', sortable: false, width: 400 }
    ];

    let clientData = [];
    const clientListData = function () {
        clientService.getAll(paging).then(response => {
            response.map((item, index) => {
                clientData.push({
                    id: item.clientId,
                    clientName: item.clientName,
                    createdBy: item.createdBy
                })
            })
            setClientList(clientData);
        })
            .catch(err => {
                console.log(err);
                alert("client data is not loaded.")
            });
    }




    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (e,newpage) => {
        setPaging({
            pageNo:newpage,
            pageSize:5
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setPaging({
            pageNo:0,
            pageSize:parseInt(event?.target.value, 10)
        })
    };

    return (<>
        <MainCard title="Client List">
            <TableContainer>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Client Name</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientList.map((item, index) => {
                            return (
                                <TableRow >
                                    <TableCell>{item.clientName}</TableCell>
                                    <TableCell>{item.createdBy}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit client">
                                            <IconButton color="secondary" size="large">
                                                <Link to={`/clients/EditClient?clientId=${item.id}`}>
                                                    <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                </Link>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 50]}
                                component="div"
                                count={clientCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>

                </Table>
            </TableContainer>
        </MainCard>
    </>)
};

export default ClientList;