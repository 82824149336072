import {
    Button,
    Divider,
    Grid,
    ListItemText,
    Typography,
    ListItemButton
} from "@mui/material";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useEffect, useState, useRef } from 'react';
import * as WebDataRocksReact from 'react-webdatarocks';
import ColorTabs from 'ui-component/basic/UITabs/ColorTabs';
import { useTheme } from '@mui/material/styles';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ShowChartIcon from '@mui/icons-material/ShowChart';
// import  'assets/css/highchart-grid-light.css';
const icons = { EqualizerIcon, AutoGraphIcon, ShowChartIcon };

const Question = ({ questionData, emelentId, role = 'View', questionDRJsonData, addDRCustomQuestionHandler }) => {
    const theme = useTheme();
    // const [pivot, setPivot] = useState({});
    const [showTable, setShowTable] = useState(false);
    const [graphType, setGraphType] = useState((questionDRJsonData?.graphType == undefined) ? 'bar' : questionDRJsonData.graphType);
    let graphTypeToSave = useRef('bar');

    console.log("questionDRJsonData:", questionDRJsonData);
    const gridid = 'grid' + emelentId;
    const chartid = 'barChartContainer' + emelentId;
    var pivot = null;
    var chart = null;
    const exportSeeting = {
        enabled: true,
        buttons: {
            contextButton: {
                enabled: false,
                menuItems: [
                    { text: "Download Image (PNG)" },
                    { text: "Download PDF Document" },
                    { text: "Export as CSV" },
                    { separator: true },
                    { text: "Show Labels" }
                ]
            }
        },
        filename: "REDACTED",
        csv: { dateFormat: "%Y-%m-%dT%H:%M:%S-04:00" }
    }
    useEffect(() => {
        //code for table and graph
        //setGraphType((questionDRJsonData?.graphType == null || questionDRJsonData?.graphType == '' || questionDRJsonData?.graphType == undefined) ? 'bar' : questionDRJsonData.graphType)
        prepareQuestion();
        Highcharts.setOptions({
            plotOptions: {
                series: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        console.log('graphType:', graphType);
        Highcharts.setOptions({
            plotOptions: {
                series: {
                    colorByPoint: true
                }
            }
        });
    }, [graphType]);

    function prepareQuestion() {
        pivot = new WebDataRocks({
            container: '#' + gridid,
            toolbar: true,
            beforetoolbarcreated: customizeToolbar,
            report: {
                dataSource: {
                    data: (questionDRJsonData == undefined) ? questionData.data : questionDRJsonData.data
                },
                slice: questionDRJsonData?.slice,
                formats: questionDRJsonData?.formats
            },
            reportcomplete: function () {
                pivot.off("reportcomplete");
                // if (theme.palette.mode === 'dark')
                // applyTheme();
                pivot.highcharts.getData({
                    type: graphType
                }, function (data) {
                    if (chart !== null)
                        chart.destroy();
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    chart = Highcharts.chart(chartid, chartData);
                }, function (data) {
                    if (chart !== null)
                        chart.destroy();
                    let chartData = {
                        ...data,
                        exporting: exportSeeting,
                        slice: questionDRJsonData?.slice,
                        formats: questionDRJsonData?.formats
                    }
                    chart = Highcharts.chart(chartid, chartData);
                });
            },
        });
    }

    function customizeToolbar(toolbar) {
        pivot.toolbar = toolbar;
        pivot.toolbar.saveHandler = function (element) {
            addDRCustomQuestionHandler(questionData, webdatarocks.getReport(), graphTypeToSave.current);
        };

        // Show tabs based on role
        showHideTabs(toolbar);
    }

    function showHideTabs(toolbar) {
        var tabs = toolbar.getTabs();
        toolbar.getTabs = function () {
            if (role == 'View')
                delete tabs[2]; // delete the first tab

            tabs.unshift({
                id: "wdr-tab-graphtype",
                title: "Graph Type",
                handler: graphtypeHandler,
                icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AutoGraphIcon" tabindex="-1" title="AutoGraph"><path d="M14.06 9.94 12 9l2.06-.94L15 6l.94 2.06L18 9l-2.06.94L15 12l-.94-2.06zM4 14l.94-2.06L7 11l-2.06-.94L4 8l-.94 2.06L1 11l2.06.94L4 14zm4.5-5 1.09-2.41L12 5.5 9.59 4.41 8.5 2 7.41 4.41 5 5.5l2.41 1.09L8.5 9zm-4 11.5 6-6.01 4 4L23 8.93l-1.41-1.41-7.09 7.97-4-4L3 19l1.5 1.5z"></path></svg>`,
                menu: [
                    { title: 'Bar', id: "wdr-tab-graph-bar", handler: barGraphHandeler, icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AlignHorizontalLeftIcon" tabindex="-1" title="AlignHorizontalLeft"><path d="M4 22H2V2h2v20zM22 7H6v3h16V7zm-6 7H6v3h10v-3z"></path></svg>` },
                    { title: 'Column', id: "wdr-tab-graph-column", handler: columnGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z"></path></svg>` },

                    { title: 'Area spline range', id: "wdr-tab-graph-areasplinerange", handler: areasplinerangeGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="m3.5 18.49 6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"></path></svg>` },
                    { title: 'Bubble', id: "wdr-tab-graph-bubble", handler: bubbleGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><circle cx="7.2" cy="14.4" r="3.2"/> <circle cx="14.8" cy="18" r="2"/> <circle cx="15.2" cy="8.8" r="4.8"/></svg>` },
                    { title: 'Column range', id: "wdr-tab-graph-columnrange", handler: columnrangeGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ColumnrangeIcon" tabindex="-1" title="Columnrange"><path d="M7.5 21H2V9h5.5v12zm7.25-18h-5.5v18h5.5V3zM22 11h-5.5v10H22V11z"></path></svg>` },

                    { title: 'Funnel', id: "wdr-tab-graph-funnel", handler: funnelGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/></svg>` },
                    { title: 'Pie chart', id: "wdr-tab-graph-pie", handler: pieGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"></path></svg>` },

                    { title: 'Pyramid', id: "wdr-tab-graph-pyramid", handler: pyramidGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><polygon points="200,10 250,190 160,210" style={{fill:"gray" }} /></svg>` },

                    { title: 'Spline', id: "wdr-tab-graph-spline", handler: splineGraphHandeler, args: "html", icon: `<svg height=36 width=36 class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BarChartIcon" tabindex="-1" title="BarChart"><path d="M21 8c-1.45 0-2.26 1.44-1.93 2.51l-3.55 3.56c-.3-.09-.74-.09-1.04 0l-2.55-2.55C12.27 10.45 11.46 9 10 9c-1.45 0-2.27 1.44-1.93 2.52l-4.56 4.55C2.44 15.74 1 16.55 1 18c0 1.1.9 2 2 2 1.45 0 2.26-1.44 1.93-2.51l4.55-4.56c.3.09.74.09 1.04 0l2.55 2.55C12.73 16.55 13.54 18 15 18c1.45 0 2.27-1.44 1.93-2.52l3.56-3.55c1.07.33 2.51-.48 2.51-1.93 0-1.1-.9-2-2-2z"></path><path d="m15 9 .94-2.07L18 6l-2.06-.93L15 3l-.92 2.07L12 6l2.08.93zM3.5 11 4 9l2-.5L4 8l-.5-2L3 8l-2 .5L3 9z"></path></svg>` },
                ]
            });

            return tabs;
        }

        var barGraphHandeler = function () {
            // setGraphType(graphType => { return 'bar'; });
            graphTypeToSave.current = 'bar';
            pivot.highcharts.getData({
                type: "bar"
            }, function (data) {
                if (chart !== null)
                    chart.destroy();
                let chartData = {
                    ...data,
                    exporting: exportSeeting,
                    slice: questionDRJsonData?.slice,
                    formats: questionDRJsonData?.formats
                }
                chart = Highcharts.chart(chartid, chartData);
            }, function (data) {
                if (chart !== null)
                    chart.destroy();
                let chartData = {
                    ...data,
                    exporting: exportSeeting,
                    slice: questionDRJsonData?.slice,
                    formats: questionDRJsonData?.formats
                }
                chart = Highcharts.chart(chartid, chartData);
            });
        }

        var columnGraphHandeler = function () {
            // setGraphType(graphType => { return 'column'; });
            graphTypeToSave.current = 'column';
            pivot.highcharts.getData({
                type: "column"
            }, function (data) {
                if (chart !== null)
                    chart.destroy();
                let chartData = {
                    ...data,
                    exporting: exportSeeting,
                    slice: questionDRJsonData?.slice,
                    formats: questionDRJsonData?.formats
                }
                chart = Highcharts.chart(chartid, chartData);
            }, function (data) {
                if (chart !== null)
                    chart.destroy();
                let chartData = {
                    ...data,
                    exporting: exportSeeting,
                    slice: questionDRJsonData?.slice,
                    formats: questionDRJsonData?.formats
                }
                chart = Highcharts.chart(chartid, chartData);
            });
        }

        //Other graph types
        function createAndUpdateChart(data) {
            if (chart !== null)
                chart.destroy();
            let chartData = {
                ...data,
                exporting: exportSeeting,
                slice: questionDRJsonData?.slice,
                formats: questionDRJsonData?.formats
            }
            chart = Highcharts.chart(chartid, chartData);
        }

        var areasplinerangeGraphHandeler = function () {
            graphTypeToSave.current = 'areasplinerange';
            pivot.highcharts.getData({
                type: "areasplinerange"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }

        var bubbleGraphHandeler = function () {
            graphTypeToSave.current = 'bubble';
            pivot.highcharts.getData({
                type: "bubble"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }

        var columnrangeGraphHandeler = function () {
            graphTypeToSave.current = 'columnrange';
            pivot.highcharts.getData({
                type: "columnrange"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }

        var funnelGraphHandeler = function () {
            graphTypeToSave.current = 'funnel';
            pivot.highcharts.getData({
                type: "funnel"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }

        var pieGraphHandeler = function () {
            graphTypeToSave.current = 'pie';
            pivot.highcharts.getData({
                type: "pie"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }

        var pyramidGraphHandeler = function () {
            graphTypeToSave.current = 'pyramid';
            pivot.highcharts.getData({
                type: "pyramid"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }

        var splineGraphHandeler = function () {
            graphTypeToSave.current = 'spline';
            pivot.highcharts.getData({
                type: "spline"
            }, function (data) {
                createAndUpdateChart(data);
            }, function (data) {
                createAndUpdateChart(data);
            });
        }


    }
    let graphtypeHandler = function () {
    }

    function handleShowTable(value) {
        setShowTable(showTable === false ? true : false);
    }
    function handleExportGraph() {
        if (chart != null)
            chart.exportChart();
    }



    return ((questionData) && <>
        <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={4}>
                <Grid item>
                    <Button onClick={() => handleExportGraph(false)}
                        variant="contained"                        >
                        Export Graph
                    </Button>
                </Grid>
                <Grid item>
                    <AnimateButton
                        scale={{
                            hover: 1.1,
                            tap: 0.9
                        }}
                    >
                        <Button onClick={() => handleShowTable(false)}
                            variant="contained"
                            color="secondary"
                            sx={{
                                boxShadow: theme.customShadows.secondary,
                                ':hover': {
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            {showTable == true ? 'View Graph' : 'View Data'}
                        </Button>
                    </AnimateButton>
                </Grid>

                {/* <Grid item>
                    <AnimateButton type="slide">
                        <Button onClick={() => handleShowTable(true)}
                            variant="contained"
                            style={{ background: theme.customShadows.secondary, borderColor: '#EDE7F6' }}
                            sx={{
                                boxShadow: theme.customShadows.error,
                                ':hover': {
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            View Data
                        </Button>
                    </AnimateButton>
                </Grid> */}

            </Grid>
            <Divider sx={{ height: 10, m: 0.5, mr: 1.5 }} orientation="vertical" />
            <Grid item xs={12} sm={12}>
                <div id={'grid' + `${emelentId}`} className={showTable == false ? 'hide-div' : 'show-div'}></div>
                <div id={'barChartContainer' + `${emelentId}`} className={showTable == true ? 'hide-div' : 'show-div'}></div>

            </Grid>
        </Grid>


        {/* <ColorTabs data={tabData} /> */}
    </>);


    function applyTheme() {
        debugger;
        Highcharts.createElement('link', {
            href: 'https://fonts.googleapis.com/css?family=Unica+One',
            rel: 'stylesheet',
            type: 'text/css'
        }, null, document.getElementsByTagName('head')[0]);

        Highcharts.theme = {
            colors: ['#44cadb', '#ffa600', '#abc438', '#1dcec8', '#2dd0ac', '#58cf88', '#81cc60',
                '#d5b80c', '#DF5353', '#7798BF', '#aaeeee'
            ],
            chart: {
                backgroundColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 1,
                        y2: 1
                    },
                    stops: [
                        [0, '#2a2a2b'],
                        [1, '#3e3e40']
                    ]
                },
                style: {
                    fontFamily: `'Montserrat','Unica One', sans-serif`
                },
                plotBorderColor: '#606063'
            },
            title: {
                style: {
                    color: '#E0E0E3',
                    textTransform: 'uppercase',
                    fontSize: '20px'
                }
            },
            subtitle: {
                style: {
                    color: '#E0E0E3',
                    textTransform: 'uppercase'
                }
            },
            xAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: '#E0E0E3'
                    }
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    style: {
                        color: '#A0A0A3'

                    }
                }
            },
            yAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: '#E0E0E3'
                    }
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                tickWidth: 1,
                title: {
                    style: {
                        color: '#A0A0A3'
                    }
                }
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#F0F0F0'
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,

                    dataLabels: {
                        color: '#B0B0B3'
                    },
                    marker: {
                        lineColor: '#333'
                    }
                },

                boxplot: {
                    fillColor: '#505053'
                },
                candlestick: {
                    lineColor: 'white'
                },
                errorbar: {
                    color: 'white'
                }
            },
            legend: {
                itemStyle: {
                    color: '#E0E0E3'
                },
                itemHoverStyle: {
                    color: '#FFF'
                },
                itemHiddenStyle: {
                    color: '#606063'
                }
            },
            credits: {
                style: {
                    color: '#666'
                }
            },
            labels: {
                style: {
                    color: '#707073'
                }
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3'
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3'
                }
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053'
                    }
                }
            },

            // scroll charts
            rangeSelector: {
                buttonTheme: {
                    fill: '#505053',
                    stroke: '#000000',
                    style: {
                        color: '#CCC'
                    },
                    states: {
                        hover: {
                            fill: '#707073',
                            stroke: '#000000',
                            style: {
                                color: 'white'
                            }
                        },
                        select: {
                            fill: '#000003',
                            stroke: '#000000',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                },
                inputBoxBorderColor: '#505053',
                inputStyle: {
                    backgroundColor: '#333',
                    color: 'silver'
                },
                labelStyle: {
                    color: 'silver'
                }
            },

            navigator: {
                handles: {
                    backgroundColor: '#666',
                    borderColor: '#AAA'
                },
                outlineColor: '#CCC',
                maskFill: 'rgba(255,255,255,0.1)',
                series: {
                    color: '#7798BF',
                    lineColor: '#A6C7ED'
                },
                xAxis: {
                    gridLineColor: '#505053'
                }
            },

            scrollbar: {
                barBackgroundColor: '#808083',
                barBorderColor: '#808083',
                buttonArrowColor: '#CCC',
                buttonBackgroundColor: '#606063',
                buttonBorderColor: '#606063',
                rifleColor: '#FFF',
                trackBackgroundColor: '#404043',
                trackBorderColor: '#404043'
            },

            // special colors for some of the
            legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
            background2: '#505053',
            dataLabelsColor: '#B0B0B3',
            textColor: '#C0C0C0',
            contrastTextColor: '#F0F0F3',
            maskColor: 'rgba(255,255,255,0.3)'
        };

        // Apply the theme
        Highcharts.setOptions(Highcharts.theme);
    }

}
export default Question;