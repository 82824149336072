import other from './other';
import masters from './masters';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [pages]
};

export default menuItems;
